@import 'utils/scss_variables.module.scss';

.ps-field-wrapper.ps-multiselect-field-wrapper {
    .selector-wrapper {
        position: relative;

        .multi-select__control {
            background-color: inherit;
            border-color: $color-neutral-grey-light;
            border-radius: 2px;

            .multi-select__placeholder {
                color: $color-text-light;
            }
            .multi-select__indicators {
                .multi-select__indicator svg {
                    color: $color-neutral-grey;
                }
                .multi-select__indicator-separator {
                    background-color: $color-neutral-grey-light;
                }
            }
            &.multi-select__control--is-disabled {
                .multi-select__placeholder,
                .multi-select__indicators {
                    display: none;
                }
                .multi-select__multi-value {
                    background-color: $color-grey;
                }
            }
            &.multi-select__control--is-focused {
                box-shadow: none;
            }    
            .multi-select__value-container.multi-select__value-container--has-value {
                .multi-select__input-container {
                    height: 28px;
                    color: $color-text-white;

                    .multi-select__input {
                        color: $color-text-white;
                    }
                }

                &:active,
                &:hover,
                &:focus-within {
                    > :not(.multi-select__multi-value) {
                        display: block;
                    }
                }
                > :not(.multi-select__multi-value) {
                    display: none;
                }
            }
            .multi-select__multi-value,
            .multi-select__multi-value:focus,
            .multi-select__multi-value__label:focus,
            .multi-select__multi-value__remove:focus {
                outline: none;
            }
            .multi-select__multi-value {
                background-color: $color-main;
                color: $color-white;
                border-radius: 50px;
    
                .multi-select__multi-value__label {
                    color: $color-white;
                    padding-left: 10px;
                }
                .multi-select__multi-value__remove {
                    border-radius: 50px;
                    cursor: pointer;
                    opacity: 0.4;
    
                    &:hover {
                        background-color: $color-main;
                        color: $color-white;
                        opacity: 1;
                    }
                }
            }
        }
        .multi-select__menu {
            background-color: $color-background;
            border: 1px solid $color-neutral-grey-light;

            .multi-select__option {
                background-color: $color-background;
                
                &:hover:not(.disabled) {
                    background-color: $color-hover;
                }
            }
        }
    }
    
}