@import 'utils/scss_variables.module.scss';

.cicd-zip-details-page {
    .zip-details-subtitle-wrapper {
        color: $color-text-light;
        margin-top: 10px;
    }
    .table-wrapper-new {
        .table-action-buttons-container {
            display: flex;
            justify-content: right;
        }
        .table .table-body {
            height: calc(100vh - #{$top-nav-height} - 350px);
        }
    }
}