@import 'utils/scss_variables.module.scss';

$button-modal-width-small: 150px;
$button-modal-transition: height .5s, width .5s;

.button-modal-container {
    position: absolute;
    width: $button-modal-width-small;
    height: 54px;
    top: 42px;
    right: 1px;
    background-color: $color-main-medium;
    box-shadow: -8px 12px 19px rgba(55, 72, 95, 0.13);
    border-radius: 2px;
    transition: $button-modal-transition;

    &.expanded {
        width: 495px;
        transition: $button-modal-transition;
        height: calc(100% - 45px);
        background-color: $color-main-dark;

        .close-button {
            top: 15px;
            right: 10px;
        }
    }
    .icon-container .icon {
        color: $color-text-white;
    }
    .main-button {
        width: $button-modal-width-small;
        padding: 15px;
    }
    .notification-container {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 9px;
        line-height: 14px;
        font-weight: bold;
        border-radius: 50px;
        width: 16px;
        height: 16px;
        background-color: $color-error;
        color: $color-white;
        text-align: center;
    }
}