@import 'utils/scss_variables.module.scss';

.icon {
    display: inline-block;
    width: $default-icon-size;
    height: $default-icon-size;
    min-width: $default-icon-size;
    min-height: $default-icon-size;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;

    &.clickable:not(.disabled) {
        cursor: pointer;
    }
    &.disabled {
        cursor: not-allowed;
        color: $color-disabled;
    }
}