@import 'utils/scss_variables.module.scss';

.notification-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: $color-alpha-blue;
    color: $color-text-white;
    height: 50px;
    font-size: 11px;
    line-height: 16px;
    display: flex;
    align-items: center;
    padding: 0 30px;
    filter: drop-shadow(0px 5px 10px rgba(34, 43, 54, 0.11));
    z-index: $top-layer-z-index + 5;

    &.error {
        background-color: $color-alpha-red;
    }
    &.warning {
        background-color: $color-alpha-orange;
        color: $color-text-dark;

        .close-button {
            color: $color-text-dark;
        }
    }
    &.success {
        background-color: $color-alpha-green;
    }
    .notification-content {
        width: 100%;
    }
}