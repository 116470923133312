@import 'utils/scss_variables.module.scss';

.protected-pod-value-display-wrapper {
    display: flex;
    font-weight: bold;
    align-items: center;

    .protect-icon {
        color: $color-main-light;
    }

    .text {
        text-decoration: underline;
        color: $color-main-light;
    }
}

.modal-outer .modal-container.unprotected-pod-modal {
    top: 5%;
    width: 600px;
    max-height: 350px;

    .unprotected-pod-data-container {
        .unprotected-pod-title {
            margin-bottom: 15px;
        }
        .instructions-item.alt-unprotected-pod-command textarea {
            height: 45px;
        }
    }
    button {
        top: 290px;
        right: 30px;
        position: absolute;
    }
    .item-text-wrapper {
        width: calc(100% - 15px);

        .item-link {
            font-weight: bold;
            margin-right: 5px;
            cursor: pointer;
            color: $color-main;
            text-decoration: underline;
        }
    }
}