@import 'utils/scss_variables.module.scss';

.select-panes-wrapper {
    display: flex;
    min-height: 500px;

    .select-pane {
        width: 33%;
        flex-shrink: 0;
        border-right: 1px solid $color-neutral-grey-light;

        .select-pane-title {
            font-size: 12px;
            font-weight: 700;
            padding: 20px;
        }
    }
    .display-pane {
        flex-grow: 1;
        flex-wrap: wrap;
    }
}