@import 'utils/scss_variables.module.scss';

.instructions-item {
    padding: 5px 0;

    .title-container {
        display: flex;
        align-items: center;

        .list-indicator {
            width: 4px;
            height: 4px;
            background-color: $color-neutral-grey;
            border-radius: 50px;

            &.is-inner {
                margin-left: 10px;
                background-color: $color-white;
                border: 1px solid $color-main;
            }
        }
    }
    .item-title {
        margin-left: 9px;
        font-size: 11px;

        &.with-bullet {
            margin-left: 5px;
        }
    }
    .input-with-copy-wrapper {
        margin-top: 10px;
    }
}