@import 'utils/scss_variables.module.scss';

.deployment-policy-user-rule-form {
    .pod-validation-field-container,
    .sidecars-field-container {
        display: flex;
        justify-content: space-around;

        .ps-field-wrapper {
            width: 50%;

            &:last-child {
                margin-left: 10px;
            }
        }
    }
    .tokens-step-wrapper {
        margin-bottom: 20px;

        .token-wrapper {
            .token-header {
                display: flex;
                align-items: baseline;
                justify-content: space-between;
                border-bottom: 1px solid $color-neutral-grey-light;
    
                .token-title {
                    color: $color-main;
                    font-weight: bold;
                    font-size: 12px;
                    line-height: 14px;
                    padding: 12px 0;
                    white-space: nowrap;
                }
                .toggle-token {
                    align-self: center;
                    
                    .disabled {
                        color: $color-disabled;
                    }
                }
            }
            .token-content {
                background-color: rgba($color-status-blue, 0.1);
                padding: 20px;
                padding-bottom: 5px;
            }
            .token-actions-wrapper {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                width: 100%;
                padding: 10px 0;

                .round-icon-container {
                    &:not(:last-child) {
                        margin-right: 5px;
                    }
                }
            }
        }
        
    }
}