@import 'utils/scss_variables.module.scss';

.ps-radio-container {
    .ps-radio-wrapper {
        display: flex;
        align-items: center;
        position: relative;
        padding: 5px;
        cursor: pointer;

        &.disabled {
            cursor: not-allowed;

            .ps-radio-text {
                color: $color-disabled;
            }
            .checkmark {
                border-color: $color-disabled;

                &:after {
                    background-color: $color-disabled !important;
                }
            }
        }
        .info-icon-tooltip-wrapper {
            margin-left: 10px;
        }
        .ps-radio-text {
            padding-left: 16px;
            -webkit-box-decoration-break: clone;
            box-decoration-break: clone;
        }
        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;

            &:checked ~ .checkmark:after {
                content: "";
                position: absolute;
                display: block;
                top: 1px;
                left: 1px;
                width: 6px;
                height: 6px;
                border-radius: 50px;
                background: $color-main-light;
            }
        }
        .checkmark {
            position: absolute;
            top: 11px;
            left: 5px;
            height: 10px;
            width: 10px;
            background-color: inherit;
            border-radius: 50px;
            border: 1px solid $color-neutral-grey;
        }
    }

    &.horizontal {
        display: table;
        
        .ps-radio-wrapper {
            float: left;
            margin-right: 15px;
        }
    }
    &.small {
        font-size: 11px;

        .ps-radio-wrapper .checkmark {
            top: 7px;
        }
    }
}