@import 'utils/scss_variables.module.scss';

.settings-wrapper {
    padding-bottom: 10px;

    .container-title:not(:first-child) {
        padding-top: 10px;
    }
    .setting-container {
        background-color: $color-main-dark;
        border: 1px solid $color-neutral-grey-light;
        box-sizing: border-box;
        margin-bottom: 20px;

        .setting-content-container {
            padding: 25px;
            padding-bottom: 10px;
            font-weight: bold;
            font-size: 10px;
            line-height: 11px;

            .truncate-form-fields {
                display: flex;
                align-items: center;

                .truncate-value-field {
                    margin-left: 20px;
                }
            }
            .agent-duration-form-fields {
                display: flex;
                align-items: center;
                margin-bottom: 20px;
                
                .ps-duration-field-wrapper {
                    margin-bottom: 0;
                }
                .duration-input-container {
                    margin-left: 10px;
                }
            }
            .agent-update-form-fields {
                display: flex;
                align-items: center;
                padding: 10px 0;

                .update-mode-field {
                    display: flex;
                    margin-top: -11px;
                    margin-bottom: 0;
                    padding: 7px 0;
                    
                    .ps-field-label-wrapper {
                        margin-right: 10px;
                    }
                    .ps-radio-container .ps-radio .checkmark {
                        top: 5px;
                    }
                }
                .menual-config-wrapper {
                    display: flex;
                    align-items: center;
                    padding: 10px;
                    margin-top: -20px;
                    border-left: 1px solid $color-neutral-grey-light;

                    .update-btn {
                        margin-left: 20px;
                    }
                }
            }
        }
    }
    .save-settings-btn {
        position: absolute;
        top: 5px;
        right: 15px;

        &.error {
            border: 1px solid $color-error;
            .icon,
            .icon-title {
                color: $color-error !important;
            }
        }
        .icon-container {
            background-color: transparent;
            
            .icon {
                color: $color-white;
            }
            .icon-title {
                font-size: 12px;
            }
            &.disabled .icon {
                color: $color-disabled;
            }
        }
        
    }
}