@import 'utils/scss_variables.module.scss';

$filters-form-height: 100px;

.runtime-connections-page {
    .table-filters-container {
        height: $filters-form-height;

        .table-filters-form {
            margin: 15px 0;
            align-items: center;
        }
    }
    .table-wrapper-new {
        .table {
            .table-head {
                .table-tr:first-child .table-th {
                    background-color: $color-background;
                }
            }
            .table-body {
                height: calc(100vh - 2 * #{$main-content-padding} - #{$top-nav-height} - #{$filters-form-height} - 150px);
    
                .actions-column-container .ps-dropdown.table-cell-action-container .dropdown-menu {
                    width: 100px;
                    transform: translate3d(-75px, 22px, 0px) !important;
                }
            }
        }
        .table-action-buttons-container {
            position: absolute;
            top: -120px;
            right: 0;
        }
        .connections-add-rule-icon {
            color: $color-main;

            &.audit-mode {
                color: $color-grey-dark !important;
            }
        }
    }
}
