@import 'utils/scss_variables.module.scss';

$table-default-height: calc(100vh - #{$top-nav-height} - 350px);

.cicd-image-details-page {
    .image-details-subtitle-wrapper {
        color: $color-text-light;
        margin-top: 10px;
    }
    .table-wrapper-new {
        .table-action-buttons-container {
            display: flex;
            justify-content: right;
        }
        .grouped-table-container {
            clear: both;
            margin: 20px;
        }
        .table .table-body {
            height: $table-default-height;
        }
        .table-no-header-loader-container,
        .table-empty-results {
            min-height: $table-default-height;
        }
    }
}