@import 'utils/scss_variables.module.scss';

$calendar-font-size: 11px;
$calendar-nav-size: 22px;

.rdp {
    --rdp-cell-size: 30px;
    --rdp-accent-color: $color-main;
    --rdp-background-color: $color-white;
}

.ps-calendar {
    margin: 0;
    margin-bottom: 20px;
    background-color: $color-main-dark;
    color: $color-text-white;

    .rdp-caption {
        .rdp-caption_label {
            font-size: $calendar-font-size;
            font-weight: normal;
            text-transform: uppercase;
            color: $color-text-white;
        }
        .rdp-nav {
            height: $calendar-nav-size;
            color: $color-text-light;

            .rdp-button {
                width: $calendar-nav-size;
                height: $calendar-nav-size;

                &:hover {
                    background-color: transparent;
                }
            }
        }
    }
    .rdp-table {
        .rdp-head_cell {
            font-size: $calendar-font-size;
            font-weight: normal;
            color: $color-text-light;
        }
        .rdp-day.rdp-button {
            font-size: $calendar-font-size;
            font-weight: normal;
            color: $color-text-white;

            &:disabled:not(.rdp-day_selected) {
                opacity: 1;
                color: $color-disabled;
            }
            &.rdp-day_selected {
                color: $color-text-dark;
                background-color: $color-main-light;
                border-radius: 0;

                &.rdp-day_range_middle {
                    color: $color-text-white;
                    background-color: rgba($color-status-blue, 0.1);

                    &.rdp-day_disabled {
                        color: $color-grey;
                    }
                }
            }
        }
    }
}