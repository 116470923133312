@import 'utils/scss_variables.module.scss';

.images-page {
    .table-header-container {
        display: flex;
        margin-bottom: 10px;

        .ps-form.table-filters-form {
            margin-left: 40px;

            .ps-text-field-wrapper input {
                width: 160px;
            }
        }
    }
    .table-wrapper-new {
        .table-action-buttons-container {
            width: 520px;
            position: absolute;
            top: -44px;
            right: 0;

            .image-approve-actions {
                display: flex;

                >:not(:last-child) {
                    margin-right: 10px;
                }
            }
        }
        .status-icon {
            color: $color-warning;

            &.approved {
                color: $color-success;
            }
        }
        .image-row-actions .icon {
            color: $color-main;
        }
        .image-link-button {
            text-align: left;
            line-height: 16px;
        }
    }
}