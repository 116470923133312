.psp-profile-form {
    .ps-field-wrapper.allowedHostPaths-field {
        .ps-field-wrapper.ps-checkbox-field-wrapper {
            width: 120px;

            .ps-checkbox {
                height: 38px;
                margin-bottom: 0;
                padding-top: 10px;
    
                .checkmark {
                    top: 15px;
                }
            }
        }
    }
    .psp-profile-seccomp-container {
        display: flex;
        justify-content: space-between;
        position: relative;
        align-items: center;
    
        .ps-select-field-wrapper {
            width: calc(100% - 80px);
        }
        .ps-loader {
            z-index: 1;
        }
    }
}