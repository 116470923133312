@import 'utils/scss_variables.module.scss';

.cicd-plugins-page {
    .cd-plugins-container,
    .ci-plugins-container,
    .ide-plugins-container {
        margin-bottom: 40px;

        .cli-container {
            font-size: 11px;
        }
        .instructions-item.instructions-item-command textarea {
            height: 55px;
        }
        .instructions-item.circle-orb textarea {
            height: 55px;
        }
        .instructions-item.helm3-machine-commands textarea {
            height: 55px;
        }
    }

    .helm-download-links {
        display: flex;
        margin: 15px 10px;

        :not(:last-child) {
            margin-right: 25px;
        }
        .helm-download-link {
            display: flex;
            align-items: center;
            font-size: 10px;
            line-height: 14px;
            font-weight: 700;
            text-decoration: none;

            .icon {
                margin-right: 10px;
            }
        }
    }
}
