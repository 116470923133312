@import 'utils/scss_variables.module.scss';

.accounts-page {
    margin: $main-content-padding;
    
    .table-header-container {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .add-new-button {
            margin-right: 10px;
        }
    }
    .table-wrapper-new {
        .table .table-body {
            height: calc(100vh - 2 * #{$main-content-padding} - #{$top-nav-height} - 80px);
        }
        .table-action-buttons-container {
            position: absolute;
            top: -45px;
            right: 0;
        }
        .account-row-actions {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            .icon:not(.disabled) {
                color: $color-main;
            }
        }
    }
}
.account-pricing-wrapper {
    display: flex;
    justify-content: space-between;

    .icon:not(.disabled) {
        color: $color-main;
    }
}