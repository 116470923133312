@import 'utils/scss_variables.module.scss';

.data-graph-container {
    .data-graph-title {
        font-size: 9px;
        font-weight: bold;
        color: $color-text-light;
    }
    .data-graph-content {
        padding: 10px 0;
        width: 100%;
        height: 200px;
        font-size: 9px;

        .data-graph-legend {
           display: flex;
           margin-left: 65px;

           .data-graph-legend-item {
               display: flex;
               align-items: center;
               margin-right: 20px;
               font-weight: bold;
               color: $color-grey-dark;

               .color-indicator {
                   width: 5px;
                   height: 5px;
                   border-radius: 30px;
                   margin-right: 3px;
               }
           }
        }
    }
}
.data-graph-tooltip {
    color: $color-white;
    background-color: $color-grey-black;
    padding: 5px;
    font-size: 9px;
    border-radius: 4px;
}