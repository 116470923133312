@import 'utils/scss_variables.module.scss';

.global-admin-select-container {
    min-width: 170px;
    margin-right: 15px;
    
    .ps-single-select.open {
        .single-select__control {
            .single-select-value {
                font-weight: bold;
                font-size: 12px;
                text-transform: uppercase;
                line-height: 24px;
            }
        }
        .single-select__menu{
            font-size: 12px;
            text-transform: uppercase;
            line-height: 24px;
        }
    }
    .ps-single-select:not(.open) {
        .single-select__control {
            .single-select-value {
                color: $color-white;
                font-weight: bold;
                font-size: 12px;
                
                .value-content {
                    line-height: 24px;
                    text-transform: uppercase;
                }
            }
            .single-select__indicator {
                color: $color-white;
            }
        }
    }
}