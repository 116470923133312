@import 'utils/scss_variables.module.scss';

$action-buttons-width: 52px;

.kubernetes-environments-wrapper {
    .environment-wrapper {
        display: flex;
        justify-content: space-between;

        &:last-child .environment-content {
            border-bottom: 1px solid $color-neutral-grey-light;
        }
        .environment-content {
            width: calc(100% - #{$action-buttons-width} - 20px);
            border-top: 1px solid $color-neutral-grey-light;
            padding: 10px 0;

            
            .environment-header-wrapper {
                font-size: 11px;
                font-weight: bold;
                display: flex;
                justify-content: space-between;
                padding-right: 10px;
                align-items: center;
                cursor: pointer;
            }
            .kubernetes-env-input-wrapper {
                margin-top: 20px;
            }
        }
        .environment-actions-wrapper {
            display: flex;
            justify-content: space-between;
            width: $action-buttons-width;
            padding: 10px 0;
        }
    }
}