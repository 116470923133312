@import 'utils/scss_variables.module.scss';

$side-margin: 20px;

.pagination-container {
    padding-top: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    .pagination-title {
        color: $color-text-light;
        text-transform: uppercase;
        font-size: 11px;
        font-weight: bold;
    }
    .pagination-navigation {
        float: left;
        margin-left: $side-margin;

        .pagination-title {
            margin-right: 10px;
        }
        .page-number {
            width: 40px;
            display: inline-block;
            text-align: center;
            border: 1px solid $color-neutral-grey-light;
            color: $color-text-light;
            margin: 0 10px;
        }
        .arrow-icon:not(.disabled) {
            color: $color-main-light;
        }
    }
    .pagination-results {
        float: right;
        margin-right: $side-margin;

        .result-item {
            font-size: 12px;
            cursor: pointer;
            border-radius: 50%;
            padding: 10px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            height: 40px;
            width: 40px;
            color: $color-text-light;

            &:not(:last-child) {
                margin-right: 10px;
            }
            &.selected {
                background-color: $color-main-light;
                color: $color-neutral-dark;
            }
            &:hover:not(.selected),
            &:focus:not(.selected) {
                background-color: $color-grey-light;
            }
        }
    }
}