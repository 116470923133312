@import 'utils/scss_variables.module.scss';

$advisor-width: 180px;
$content-height: calc(100vh - 2 * #{$main-content-padding} - #{$top-nav-height} - 45px);
$seccomp-width: 440px;

.advisor-modal.psp-advisor {
    &:not(.expanded) {
        width: $advisor-width;
    }
    .advisor-run-container {
        left: 170px;
    }
    .psp-advisor-content-wrapper .list-value-items-display .prop-name {
        width: 225px;
    }
}
.psp-profiles-container {
    position: relative;
    
    .seccomp-button {
        width: 104px;
        height: 53px;
        border-radius: 2px;
        background-color: $color-main;
        box-shadow: -8px 12px 19px rgba(55, 72, 95, 0.13);
        font-size: 10px;
        font-weight: bold;
        position: absolute;
        top: -17px;
        right: 170px;
        text-align: center;
        padding: 20px 0;

        &:hover {
            cursor: pointer;
        }
    }
    .psp-profiles-content {
        display: flex;
        flex-direction: row-reverse;
        margin-right: calc(-1 * #{$main-content-padding});

        .psp-profiles {
            margin-top: 10px;
            height: $content-height;
            width: 100%;
            overflow: auto;
            padding-right: $main-content-padding;
            transition: width .5s;
        }
        .seccomp-profiles {
            width: 1px;
            height: $content-height;
            overflow: auto;
            margin-top: 10px;
            transition: width .5s;
        }
        &.is-seccomp-open {
            .psp-profiles {
                width: calc(100% - #{$seccomp-width});
            }
            .seccomp-profiles {
                width: $seccomp-width;
                box-shadow: -4px 0 16px 0 rgba(38, 49, 132, 0.12);
                background-color: $color-grey-off-white;
            }
        }
    }
    
}