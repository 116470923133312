@import 'utils/scss_variables.module.scss';

$button-icon-size: 16px;

.scn-button {
    border: none;
    outline: none;
    font-weight: bold;
    background-color: transparent;
    box-sizing: border-box;
    padding: 0;
    height: 26px;
    font-size: 12px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    
    &:disabled {
        cursor: not-allowed;
    }
    &.scn-button--primary {
        background-color: $color-main-light;
        color: $color-text-dark;
        border-radius: 50px;
        text-transform: uppercase;
        padding: 0 20px;

        &:hover:not(:disabled) {
            background-color: $color-blue-bright;
        }
        &:disabled {
            background-color: $color-neutral-grey-light;
        }
    }
    &.scn-button--secondary {
        color: $color-main-light;
        border: 1px solid $color-main-light;
        border-radius: 50px;
        text-transform: uppercase;
        padding: 0 20px;

        &:hover:not(:disabled) {
            color: $color-blue-bright;
            border-color: $color-blue-bright;
        }
        &:disabled {
            color: $color-neutral-grey-light;
            border-color: $color-neutral-grey-light;
        }
    }
    &.scn-button--tertiary {
        color: $color-main-light;
        text-decoration: underline;

        &:hover:not(:disabled) {
            color: $color-blue-bright;
        }
        &:disabled {
            color: $color-disabled;
        }
    }
    .button-icon {
        width: $button-icon-size;
        height: $button-icon-size;
        min-width: $button-icon-size;
        min-height: $button-icon-size;
        margin-right: 5px;
    }
}