@import 'utils/scss_variables.module.scss';

$cicd-table-height: calc(100vh - 2 * #{$main-content-padding} - #{$top-nav-height} - 50px);

.cicd-page {
    .page-content {
        .table-container {
            .ReactTable {
                max-height: $cicd-table-height;
                min-height: $cicd-table-height;
            }
            .columns-toolbar {
                height: $cicd-table-height;
            }
        }
    }  
}