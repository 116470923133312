@import "utils/scss_variables.module.scss";

$header-border-padding: 20px;
$separator-border: 1px solid $color-grey;
$cluster-header-width: 300px;
$dahboard-header-height: 50px;
$widgets-container-height: calc(100% - #{$dahboard-header-height});
$dahboard-header-height-large: 150px;
$widgets-container-height-large: calc(100% - #{$dahboard-header-height-large});
$last-child-width: 100px;

.cis-main-page-content,
.cis-inner-page-content {
  margin-top: 20px;
  padding: 0 $main-content-padding;
}

.cis-main-page-content {
  .table-wrapper-new {
    .table .table-body {
        height: calc(100vh - 2 * #{$main-content-padding} - #{$top-nav-height} - 255px);
    }
  }
}

.cis-inner-page-content {
  .table-wrapper-new {
    .table .table-body {
        height: calc(100vh - 2 * #{$main-content-padding} - #{$top-nav-height} - 210px);
    }
  }
}

.charts-container {
  display: flex;
  justify-content: space-between;
  margin: 20px 20px;

  .item-container {
    margin: 10px 10px;
    flex-grow: 1;
    height: 88px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    padding: 18px 20px 18px 23px;
    background-color: $color-main-dark;

    > :not(:last-child) {
      margin-right: 15px;
    }
    .icon {
      color: $color-main-light;
      min-height: 32px;
      min-width: 32px;
    }
    .item-summary {
      color: $color-text-light;
      line-height: 1.2;
      font-weight: bold;
      display: flex;
      align-items: center;

      .total-count {
        color: $color-text-white;
        padding-right: 15px;
        font-size: 28px;
      }
      .item-title {
        font-size: 13px;
        flex-basis: 150px;
        white-space: pre-line;
      }
    }
    .protected-chart-container {
      display: flex;
      align-items: center;
      min-width: 90px;
      width: 90px;
      margin-left: auto;

      .legend-container {
        margin-left: 10px;
        font-size: 12px;
        font-weight: bold;

        .legent-item {
          display: flex;
          align-items: center;

          .item-mark {
            width: 4px;
            height: 4px;
            border-radius: 5px;
            margin-right: 2px;
          }
        }
      }
    }
  }
}

.cluster-test-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tests-table {
  .table-wrapper-new .table-container .table .table-td.inner-row-td {
    padding: unset;
  }
  .table-tr .table-td {
    &:first-child {
      border-right: 0px;
    }
  }
}

.sub-test-table {
  table-layout: fixed;
  .table-action-buttons-container:empty {
    display: none;
  }
  .table-wrapper-new {
    padding-left: 2rem;

    .table-container .table {
      margin-top: unset;
  
      .table-body {
        height: unset;
  
        .table-tr {
          background-color: $color-main-dark;
          border: 1px solid $color-neutral-grey-light;
          border-bottom: none;
        }
        .inner-row-td {
          background-color: $color-main;
        }
      }
      .table-head {
        z-index: 0;

        .table-th {
          background-color: $color-main-dark;
          padding-top: 10px;
          &:first-child {
            border-right: 0px;
          }
        }
      }
    }
  }
}

.nodes-list {
  margin-left: 100px;
  .readonly-nodes-list {
    padding: 10px;
  }
  .table-wrapper-new .table-container .table {
    .table-body {
      height: unset;

      .table-tr {
        background-color: $color-main;
        border: none;

        .table-td {
          border: none;
          padding: 5px 15px;
        }
      }
    }
    .table-head {
      z-index: 0;

      .table-tr {
        border: none;
        border-bottom: 1px solid $color-neutral-grey-light;
        
        .table-th {
          background-color: $color-main;
          color: $color-text-light;
        }
      }
    }
  }
  .table-action-buttons-container {
    position: absolute;
    top: -3px;
    right: 0;
    z-index: 200;
  }
}
