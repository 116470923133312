
@import 'utils/scss_variables.module.scss';

.compliance-status-icon-wrapper {
    display: flex;
    align-items: center;

    &.error {
        color: $color-error;
    }
    &.success{
        color: $color-success;
    }
    .unknown-compliance {
        border-top: 1px solid $color-grey;
        width: 22px;
    }
    span {
        margin-left: 5px;
        font-size: 10px;
        font-weight: 700;
        padding-top: 3px;
    }
}