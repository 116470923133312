@import 'utils/scss_variables.module.scss';

$item-name-width: 300px;

.list-value-items-display {
    .item-prop {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        min-height: 30px;
    
        .prop-name {
            width: $item-name-width;
            color: $color-text-white;
            font-size: 11px;
        }
        .prop-connector {
            width: 200px;
            color: $color-grey-black;
            font-size: 11px;
        }
        .prop-values {
            display: flex;
            align-items: baseline;
            flex-wrap: wrap;
            max-width: calc(100% - #{$item-name-width});
    
            .prop-value {
                color: $color-neutral-dark;
                background-color: $color-neutral-blue;
                border-radius: 2px;
                padding: 5px;
                font-size: 12px;
                margin-bottom: 5px;
    
                &:not(:last-child) {
                    margin-right: 10px;
                }
            }
            .prop-value-connector {
                color: $color-grey-black;
                font-size: 10px;
                margin-right: 10px;
            }
        }
    }
}
