@import 'utils/scss_variables.module.scss';

.runtime-events-page {
    .table-filters-container {
        height: 80px;

        .table-filters-form {
            margin: 10px 0;
        }
    }
    .table-wrapper-new {
        .table .table-body {
            height: calc(100vh - 2 * #{$main-content-padding} - #{$top-nav-height} - 180px);
        }
        .table-action-buttons-container {
            position: absolute;
            top: -95px;
            right: 0;
        }
        .events-add-rule-icon {
            color: $color-main;

            &.audit-mode {
                color: $color-grey-dark !important;
            }
        }
        .events-rule-action-container {
            display: flex;
            align-items: center;
            font-weight: bold;

            .rule-action,
            .icon {
                color: $color-text-green;

                &.blocked {
                    color: $color-error;
                }
                &.detected {
                    color: $color-warning;
                }
                &.encrypted {
                    color: $color-main;
                }
            }
            .icon {
                margin-left: 5px;
            }
                }
            }
}