@import 'utils/scss_variables.module.scss';

.permissions-page {
    position: relative;

    .breadcrumbs-title-header {
        margin-bottom: 10px;
        width: calc(100% - 170px);
    }
    .table-filters-form {
        margin-bottom: 15px;
    }
    .table-wrapper-new {
        .table-action-buttons-container {
            width: 270px;
            position: absolute;
            top: -44px;
            right: 0;
        }
    }
    .permissions-system-owners-toggle {
        position: absolute;
        top: 20px;
        right: 35px;
    }
    .ignore-toggle {
        position: absolute;
        top: 20px;
        right: 30px;
        width: 190px;
    }
    .table .table-body {
        height: calc(100vh - 2 * #{$main-content-padding} - #{$top-nav-height} - 170px);

        .cluster-permissions-owner,
        .cluster-permissions-role {
            display: flex;
            align-items: center;

            > :first-child {
                margin-right: 5px;
            }
            .icon {
                color: $color-main-light;
            }
        }
    }
    .owner-risk-assessment-permissions-wrapper {
        .table-wrapper-new {
            margin-top: 40px;
            
            .table-container .table .table-body {
                height: calc(100vh - 2 * #{$main-content-padding} - #{$top-nav-height} - 110px);
            }
        }
    }
}