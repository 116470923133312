@import 'utils/scss_variables.module.scss';

.ps-multiselect-checkbox-field-wrapper {
    .selector-wrapper {
        .input-field {
            border-radius: 2px;
            border: solid 1px $color-neutral-grey-light;
            color: $color-text-white;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            background-color: inherit;
            padding: 0 3px;
            padding-right: 15px;
            position: relative;

            .placeholder {
                color: $color-text-light;
            }
            .open-menu-icon {
                position: absolute;
                top: -1px;
                right: 0;
                color: $color-main;
            }
        }
    }
    .dropdown-menu {
        padding: 0;
        border-radius: 2px;
        background-color: $color-background;
        border: solid 1px $color-neutral-grey-light;
        color: $color-text-white;

        &:focus {
            outline: none;
        }
        .multiselect-content-container {
            padding: 10px;
            
            .checkbox-items-search {
                color: $color-text-white;
            }
            .ps-checkbox-wrapper .ps-checkbox {
                padding-left: 0;
            }
            .multiselect-item-container {
                margin-left: 10px;
                max-height: 200px;
                overflow-y: auto;
            }
        }
    }
    
}