@import 'utils/scss_variables.module.scss';

$form-field-height: 20px;
$form-field-width: 120px;
$single-select-indicator-width: 32px;
$single-select-indicator-icon-size: 13px;
$field-font-size: 10px;

.ps-form.table-filters-form {
    display: flex;

    > :not(:last-child) {
        margin-right: 10px;
    }
    .ps-field-wrapper {
        margin-bottom: 0;
    }
    .ps-field-label-wrapper {
        font-size: 9px;
        margin-bottom: 2px !important;

        label.form-label {
            font-size: 9px;
            font-weight: bold;
            margin-bottom: 0;
        }
    }
    
    .ps-text-field-wrapper {
        .ps-field-label-wrapper {
            line-height: 11px;
        }
        input {
            height: $form-field-height;
            width: $form-field-width;
            border-radius: 2px;
            font-size: $field-font-size;
        }
    }

    .ps-multiselect-checkbox-field-wrapper,
    .ps-clusters-namespaces-filter-field-wrapper {
        width: $form-field-width;

        .selector-wrapper .input-field {
            height: $form-field-height;
            font-size: $field-font-size;
            line-height: 20px;
        }
    }

    .ps-select-field-wrapper {
        .selector-wrapper {
            width: $form-field-width;

            .ps-single-select {
                .single-select__control {
                    font-size: $field-font-size;
                    min-height: calc(#{$form-field-height} - 2px);
                    max-height: $form-field-height;
                }
            }
        }
    }

    .ps-async-select-field-wrapper {
        .selector-wrapper {
            width: $form-field-width;

            .async-select__menu {
                z-index: 2;
            }
            .input-field {
                height: $form-field-height;
                font-size: $field-font-size;
            }
            .async-select__control {
                min-height: $form-field-height;
                max-height: $form-field-height;

                .async-select__value-container {
                    box-sizing: border-box;
                    height: $form-field-height;
                    font-size: $field-font-size;
                    line-height: 10px;

                    .async-select__placeholder {
                        white-space: nowrap;
                    }
                }
            }
        }
    }
}