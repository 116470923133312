@import 'utils/scss_variables.module.scss';

$wizard-width: 640px;
$wizard-height: 520px;

.modal-container.delete-modal {
    padding: 0 !important;
    top: 5% !important;
    width: $wizard-width !important;
    min-width: $wizard-width !important;
    max-height: $wizard-height !important;

    .close-button {
        top: 20px;
    }

    .step-button.submit {
        background-color: $color-error;
    }
}
