@import 'utils/scss_variables.module.scss';

$info-icon-size: 12px;

.login-page {
    position: relative;
    height: 100%;
    width: 100%;
    min-height: 1000px;
    min-width: 1200px;
     
    .background { 
        content: "";
        background-image: url('./icons/wave.svg');
        background-size: cover; 
        background-repeat: no-repeat; 
        height: 100%;
        width: 70%;
        min-width: 1000px;
        z-index: -1;
        position: absolute;
        right: 0;
        top: 0;

        .homepage-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-30%, -40%);
        }
    }
    .content-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        position: absolute;
        top: 50%;
        left: 20%;
        transform: translate(-50%, -50%);
        width: 412px;

        .product-icon {
            margin-bottom: 50px;
            max-width: 318px;
        }
        .login-desctiption-wrapper {
            font-weight: 400;
            font-size: 18px;
            line-height: 150%;

            &.small {
                font-size: 16px;
            }
        }
        .login-button-wrapper {
            margin-top: 40px;

            .login-button {
                height: 45px;
                padding: 10px 30px;
                font-weight: 700;
                font-size: 18px;
                line-height: 25px;
                text-transform: none;
                background-color: #1954A2;
                color: $color-text-white;

                &:hover {
                    background-color: #2570D2;
                }
            }
        }
    }
}