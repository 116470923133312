@import 'utils/scss_variables.module.scss';

.label-tag {
    background-color: $color-neutral-grey;
    color: $color-text-dark;
    font-weight: 500;
    font-size: 10px;
    line-height: 14px;
    height: 14px;
    padding: 0 6px;
    border-radius: 2px;
    display: inline-block;
}

.labels-wrapper {
    position: relative;
    
    &.wrap-labels {
        float: left;

        .label-tag-wrapper {
            float: left;
        }
    }
    &.wrap-labels.wrap-right {
        float: right;

        .label-tag-wrapper {
            float: right;
            margin-right: 0;

            &:not(:last-child) {
                margin-left: 5px;
            }
        }
    }
    .label-tag-wrapper {
        position: relative;

        &:not(:last-child) {
            margin-right: 5px;
        }
        .label-tag {
            display: inline;
            word-break: break-all;
            line-height: 1.5;
        }
    }
}