@import 'utils/scss_variables.module.scss';

.deployments-expansions-page {
    .table-header-container {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .add-new-button {
            margin-right: 10px;
        }
    }
    .table-wrapper-new {
        .table-action-buttons-container {
            width: 270px;
            position: absolute;
            top: -44px;
            right: 0;
        }
        .expansions-row-actions {
            display: flex;
            justify-content: space-around;
            width: 100%;
            
            .icon {
                color: $color-main;
            }
        }
        
    }
}
.modal-container.expansion-install-modal {
    max-height: 485px !important;
    top: 5% !important;

    .install-data-container {
        .install-title {
            margin-bottom: 25px;
        }
        .instructions-item {
            &.run-estracted textarea {
                height: 65px;
            }
        }
    }
    button {
        margin-top: 20px;
        float: right;
    }
}