@import 'utils/scss_variables.module.scss';

.registries-page {
    .registries-header {
        display: flex;

        > :not(:last-child) {
            margin-right: 10px;
        }
    }
    .registries-content {
        margin-top: 20px;
        font-size: 11px;

        .registry-item {
            min-height: 80px;
            background-color: $color-main-dark;
            border: 1px solid $color-neutral-grey-light;
            padding: 15px;
            display: flex;
            cursor: pointer;
            position: relative;
            margin-bottom: 20px;

            &.selected {
                border: 2px solid $color-main;
            }
            .registry-header {
                width: 250px;
                border-right: 1px solid $color-neutral-grey-lighter;
                display: flex;
                justify-content: space-around;
                flex-direction: column;

                .registry-name {
                    font-weight: bold;
                    margin: 15px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }
            }
            .registry-content {
                padding-left: 15px;
                padding-right: 50px;
                display: flex;
                align-items: center;

                .content-title {
                    font-weight: bold;
                }
                .content-clusters-list {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;

                    .cluster-item {
                        background-color: $color-neutral-grey;
                        color:  $color-neutral-dark;
                        padding: 5px;
                        border-radius: 2px;
                        margin: 5px 0 5px 15px;
                    }
                }
            }
            .registry-actions {
                position: absolute;
                top: 30px;
                right: 10px;
                display: none;

                > :not(:last-child) {
                    margin-right: 5px;
                }
                .icon:not(.disabled) {
                    color: $color-neutral-grey;
                }
            }
            &:hover {
                .registry-actions {
                    display: block;
                }
            }
        }
    }
}