@import 'utils/scss_variables.module.scss';

.container-items-wrapper {
    .container-item {
        background-color: $color-main-dark;
        border: 1px solid $color-neutral-grey-light;
        box-sizing: border-box;
        margin-bottom: 15px;

        .container-item-title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 48px;
            padding: 0 20px;
            cursor: pointer;
            font-size: 10px;
            font-weight: bold;

            .arrow-icon {
                color: $color-neutral-grey;
            }
        }
        .container-item-content {
            padding: 20px;
            border-top: 1px solid $color-neutral-grey-light;
        }
    }
}