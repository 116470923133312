@import 'utils/scss_variables.module.scss';

.empty-data-notification-wrapper {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;

    .empty-description {
        margin-top: 20px;
        color: $color-text-white;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
    }
    .buttons-container {
        margin-top: 20px;
        width: 260px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;

        .empty-data-notification-sub-link {
            font-size: 10px;
            line-height: 14px;
            white-space: nowrap;
            margin-right: 10px;
        }
        .empty-data-notification-sub-button {
            margin-right: 10px;
        }
    }
}