@import 'utils/scss_variables.module.scss';

.api-tokens-page {
    .table-header-container {
        display: flex;
        align-items: center;

        .add-new-button {
            margin-right: 30px;
        }
        .table-filters-form {
            margin: 15px 0;
        }
    }
    .table-wrapper-new {
        .table .table-body {
            height: calc(100vh - 2 * #{$main-content-padding} - #{$top-nav-height} - 150px)
        }
        .table-action-buttons-container {
            position: absolute;
            top: -45px;
            right: 0;
        }
        .token-row-actions {
            display: flex;
        }
    }
}