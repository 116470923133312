@import 'utils/scss_variables.module.scss';

$search-field-width: 290px;
$search-icon-size: 15px;
$search-icon-padding: 10px;
$search-field-padding: $search-icon-size + 2* $search-icon-padding;

.search-field-select {
    width: $search-field-width;

    .search-field-select__control {
        border-radius: 4px;
        background: url(./search-icon-standalone.svg) no-repeat scroll $search-field-width - $search-icon-size - $search-icon-padding center;
        background-size: $search-icon-size $search-icon-size;
        padding-right: $search-field-padding;
        font-size: 10px;
        border: 1px solid $color-neutral-grey-light;
        
        &:hover,
        &.search-field-select__control--is-focused {
            border-color: $color-neutral-grey-light;
            box-shadow: none;
        }
        .search-field-select__placeholder {
            color: $color-text-light;
        }
        .search-field-select__indicators {
            .search-field-select__indicator-separator,
            .search-field-select__dropdown-indicator {
                display: none;
            }
        }
        .search-field-select__input,
        .search-field-select__single-value {
            color: $color-text-light !important;
        }
    }
    .search-field-select__menu {
        background-color: $color-background;
        border: 1px solid $color-neutral-grey-light;
    }
    .search-group-label {
        color: $color-text-light;
        font-size: 10px;
        margin-bottom: 5px;
    }
}