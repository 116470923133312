
.workload-pod-template-tab-wrapper {
    .left-pane-wrapper {
        position: relative;

        .deployment-form-buttons-wrapper {
            position: absolute;
            top: 40px;
            right: 30px;

            .cancel-button {
                margin-right: 10px;
            }
        }
    }
    
}
