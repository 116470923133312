//======= Size variables =======

$side-nav-width-small: 60px;
$side-nav-width-large: 190px;
$main-content-padding: 30px;
$top-nav-height: 60px;
$top-layer-z-index: 1050;
$default-icon-size: 22px;

//======= Colour variables =======

// Brand
$color-main: #2E3E57;
$color-main-dark: #273549;
$color-main-light: #00BCEB;
$color-main-medium: #0051AF;

// Neutral
$color-neutral-grey: #A9AEB6;
$color-neutral-dark: #171F2C;
$color-neutral-grey-light: rgba(255, 255, 255, 0.15);
$color-neutral-grey-lighter: rgba(255, 255, 255, 0.2);
$color-neutral-blue: #849FC8;
$color-neutral-blue-dark: #233043;

// Status
$color-error: #F2643D;
$color-error-dark: #FF444F;
$color-warning: #FFAF45;
$color-warning-dark: #D48931;
$color-warning-low: #FFE659;
$color-warning-low-dark: #C9B021;
$color-success: #00B98D;
$color-status-violet: #A147FC;
$color-status-blue: #67ABFA;
$color-status-blue-dark: #294362;

// Status colors:
$color-alpha-red: #C55437;
$color-alpha-orange: #CF903E;
$color-alpha-green: #039878;
$color-alpha-blue: #558DCF;

// White
$color-white: #EBEBF0;

// Greys
$color-grey: #AEB5BC;
$color-grey-off-white: #F9FCFE;
$color-grey-light: #DFE2E5;
$color-grey-lighter: #E8EDF2;
$color-grey-black: #222529;
$color-grey-dark: #596068;
$color-grey-background: #F1F6FB;
$color-grey-border: #D5EAFF;
$color-grey-grid: #E4EAF1;

// Blues
$color-blue: #CFEBFB;
$color-blue-light: #EEF7FC;
$color-blue-lilac: #E6EBFC;
$color-blue-bright: #78D8FB;

//Text colors:
$color-text-yellow: $color-warning-low-dark;
$color-text-green: #3AB28E;

$color-text-white: #EBEBF0;
$color-text-lighter: #E4EAF1;
$color-text-light: $color-neutral-grey;
$color-text-dark: $color-neutral-dark;
$color-text-medium: #7E8B9C;

// Background:
$color-background: $color-neutral-dark;
$color-background-light: #273249;
$color-background-light2: #24334B;
$color-background-medium: $color-neutral-blue-dark;

// Disabled:
$color-disabled: rgba(white, 0.3);

// Hover:
$color-hover: rgba($color-main, 0.2);

//risk colors:
$color-risk-critical: $color-error-dark;
$color-risk-high: $color-error;
$color-risk-medium: $color-warning;
$color-risk-medium-text: $color-warning-dark;
$color-risk-low: $color-warning-low;
$color-risk-low-text: $color-warning-low-dark;
$color-risk-unknown: $color-status-blue;
$color-risk-approved: $color-status-violet;
$color-risk-no-known: $color-success;

$color-navigator-blue: #384D6B;
$color-tooltip: #0E141D;

 :export {
    color-main: $color-main;
    color-main-light: $color-main-light;
    color-main-dark: $color-main-dark;
    color-white: $color-white;
    color-status-blue: $color-status-blue;
    color-error: $color-error;
    color-warning: $color-warning;
    color-warning-dark: $color-warning-dark;
    color-warning-low: $color-warning-low;
    color-status-violet: $color-status-violet;
    color-success: $color-success;
    color-alpha-green: $color-alpha-green;
    color-blue-light: $color-blue-light;
    color-blue: $color-blue;
    color-grey: $color-grey;
    color-grey-light: $color-grey-light;
    color-grey-grid: $color-grey-grid;
    color-grey-dark: $color-grey-dark;
    color-grey-black: $color-grey-black;
    color-neutral-dark: $color-neutral-dark;
    color-neutral-grey: $color-neutral-grey;
    color-neutral-grey-light: $color-neutral-grey-light;
    color-neutral-grey-lighter: $color-neutral-grey-lighter;
    color-background: $color-background;
    color-text-light: $color-text-light;
    color-text-white: $color-text-white;
    color-text-dark: $color-text-dark;
    color-disabled: $color-disabled;
    color-risk-critical: $color-risk-critical;
    color-risk-high: $color-risk-high;
    color-risk-medium: $color-risk-medium;
    color-risk-medium-text: $color-risk-medium-text;
    color-risk-low: $color-risk-low;
    color-risk-low-text: $color-risk-low-text;
    color-risk-unknown: $color-risk-unknown;
    color-risk-approved: $color-risk-approved;
    color-risk-no-known: $color-risk-no-known;
    color-navigator-blue: $color-navigator-blue;
    color-tooltip: $color-tooltip;
}