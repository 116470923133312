.image-vulnerabilities-wrapper {
    .image-vulnerabilities-filter {
        margin: 10px;
        
        .layers-filters-select {
            .selector-wrapper {
                width: 400px;
            }
            .single-select-option .single-select-option-text {
                overflow-wrap: anywhere;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
            }
        }
    }
    &.with-fitler {
        .table-wrapper-new {
            .table-action-buttons-container {
                width: 270px;
                position: absolute;
                top: -44px;
                right: 0;
            }
        }
    }
}