@import 'utils/scss_variables.module.scss';

$advisor-width: 160px;

.deployments-environments-page {
    .table-header-container {
        display: flex;
        align-items: center;
        width: 200px;

        .add-new-button {
            width: 1350px;
            margin-right: 10px;
        }
    }
    .table-wrapper-new {
        .table-action-buttons-container {
            width: 270px;
            position: absolute;
            top: -44px;
            right: 0;
        }
        .environment-inner-table {
            width: 100%;
            table-layout: fixed;
        
            th, td {
                color: $color-text-white;
                padding: 5px !important;
                border-top: none !important;
            }
            th {
                text-transform: uppercase;
                font-size: 9px;
                border-bottom: 1px solid $color-neutral-grey-light;
            }
            td {
                font-size: 10px;
            }
        }
        .envs-row-actions {
            display: flex;
            justify-content: space-around;
            width: 100%;

            .icon {
                color: $color-main;
            }
        }
        .environment-name-wrapper {
            display: flex;
            align-items: center;

            .icon-with-tooltip-wrapper {
                margin-right: 5px;

                .icon {
                    color: $color-main-light;
                }
            }
        }
    }
}
.advisor-modal.environments-advisor {
    top: 1px;

    &.expanded {
        height: calc(100% - 5px);
    }
    &:not(.expanded) {
        width: $advisor-width;
    }
    .main-button {
        width: $advisor-width;
    }
    .environment-advisor-content-wrapper {
        display: flex;

        .risk-icon-container {
            display: flex;
            align-items: center;

            .risk-title {
                margin-left: 5px;
                font-size: 11px;
                line-height: 18px;
            }
        }
    }
}