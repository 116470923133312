@import 'utils/scss_variables.module.scss';

.ps-field-wrapper.ps-duration-field-wrapper {
    .duration-input-container {
        display: flex;
        align-items: center;
    
        input {
            max-width: 100px;
            height: 30px;
            color: $color-text-white;
            outline: none;
            border: 1px solid $color-neutral-grey-light;
            background-color: inherit;
            border-radius: 2px;
            padding: 0 10px;

            &:disabled {
                color: $color-disabled;
            }
        }
        .duration-type {
            margin-left: 10px;
        }
        .duration-prefix {
            margin-right: 10px;
        }
    }
}
