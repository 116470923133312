@import 'utils/scss_variables.module.scss';

.advisor-modal {
    z-index: 1;
    
    .advisor-extended-content-wrapper {
        height: calc(100% - 50px);

        .advisor-run-container {
            position: absolute;
            left: 150px;
            top: 12px;

            .advisor-scan-in-progress-wrapper {
                color: $color-main;
                background-color: $color-grey-lighter;
                border: 1px solid $color-grey;
                border-radius: 18px;
                font-weight: bold;
                font-size: 12px;
                line-height: 14px;
                padding: 6px 13px;
                text-transform: uppercase;
            }
        }
        .advisor-container {
            height: 100%;

            .ps-checkbox.select-all {
                margin-left: 15px;
            }
            .time-range-wrapper {
                display: flex;
                align-items: center;
                position: absolute;
                right: 40px;
                top: 7px;
    
                .time-range-label {
                    color: $color-text-white;
                    font-size: 9px;
                    font-weight: bold;
                    margin-right: 5px;
                }
                .time-range-select {
                    width: 125px;
                }
            }
            .suggestions-container {
                border-top: 1px solid $color-text-white;
                height: calc(100% - 95px);
                overflow-y: auto;
    
                .item-shadow {
                    height: 2px;
                    width: 100%;
                    position: relative;
                    background-color: $color-grey-off-white;
                    box-shadow: -8px 12px 18px 0 rgba(55, 72, 95, 0.13);
                }
            }
            .apply-all-button {
                position: absolute;
                right: 10px;
                bottom: 15px;
            }
        }
        .empty-suggestions {
            padding: 15px;
            text-align: center;
        }
    }
}