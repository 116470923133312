@import 'utils/scss_variables.module.scss';

$production-notification-width: 235px;

.app-wrapper {
    display: flex;
    height: 100%;
    width: 100%;

    .sidebar {
        width: $side-nav-width-small;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;

        &.open {
            width: $side-nav-width-large;
        }
    }
    [role="main"] {
        flex-basis: 0;
        flex-grow: 1;
        width: calc(100% - #{$side-nav-width-small});
        margin-left: $side-nav-width-small;
        display: flex;
        flex-direction: column;
        background-color: $color-background;
        min-width: calc(1280px - #{$side-nav-width-small}) !important;
        transition: margin .5s;
        position: relative;

        &.open {
            width: calc(100% - #{$side-nav-width-large});
            min-width: calc(1280px - #{$side-nav-width-large}) !important;
            margin-left: $side-nav-width-large;

            .top-header {
                left: $side-nav-width-large;
            }
        }
        .top-header {
            height: $top-nav-height;
            position: fixed;
            top: 0;
            right: 0;
            left: $side-nav-width-small;
            background-color: $color-background;
            z-index: calc(#{$top-layer-z-index} - 1);
            transition: left .5s;

            .header-content-main {
                display: flex;
                justify-content: space-between;
                width: 100%;
                align-items: center;
    
                .top-title {
                    margin-left: 20px;
                    font-size: 14px;
                    font-weight: bold;
                    color: $color-white;
                    text-transform: uppercase;
                    white-space: nowrap;
                }
                .header-menu-items {
                    display: flex;
                    align-items: center;
                    flex-grow: 1;
                    justify-content: flex-end;
                    margin-left: 30px;
    
                    .upgrade-account-link {
                        border-radius: 4px;
                        width: 135px;
                        height: 30px;
                        padding: 6px 10px;
                        color: $color-white;
                        font-weight: 700;
                        font-size: 9px;
                        line-height: 12px;
                        text-transform: uppercase;
                        text-decoration: none;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-right: 40px;
                    }
                    .upgrade-account-limits-exceeded-notification {
                        background-color: $color-status-blue-dark;
                        color: $color-white;
                        height: 30px;
                        border-radius: 4px;
                        padding: 0 10px 0 20px;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 16px;
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-right: 30px;
    
                        span {
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            width: calc(100% - 30px);
                        }
                        a {
                            color: $color-white;
                        }
                    }
                    .help-link {
                        margin-right: 20px;
    
                        .icon {
                            color: $color-main-light;
                        }
                    }
                    .api-link {
                        margin-right: 20px;
                        font-size: 12px;
                        font-weight: bold;
                        color: $color-main-light;
                        text-decoration: none;
                    }
                }
                .production-notification {
                    position: absolute;
                    top: 0;
                    left: calc(45% - (#{$production-notification-width} / 2));
                    width: $production-notification-width;
                    height: 39px;
                    color: $color-white;
                    text-transform: uppercase;
                    font-size: 12px;
                    font-weight: bold;
                    background-color: $color-error;
                    border-radius: 0 0 5px 5px;
                    text-align: center;
                    padding: 10px 0;
                }
            }
        }
        .content-wrapper {
            flex-grow: 1;
            margin-top: $top-nav-height;
            position: relative;
            background-color: $color-background;
        }
    }
}