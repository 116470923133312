@import 'utils/scss_variables.module.scss';

$container-margin: 20px;

.time-filter-dropdown-new {
    &,
    &:active,
    &:disabled,
    &:hover,
    &:focus {
        .btn.dropdown-toggle {
            background: inherit !important;
            border: none !important;
            color: $color-text-white;
            box-shadow: none !important;
            font-weight: bold;
            font-size: 10px;
            padding: 0;
            display: flex;
            align-items: center;
            height: 22px;

            .icon {
                margin-right: 3px;
                color: $color-main-light;

                &.caret-icon {
                    margin-top: 4px;
                    color: $color-text-light;
                }
            }
            > div {
                margin-top: 3px;
            }
            &::after {
                display: none;
            }
        }
    }

    .dropdown-menu {
        background-color: $color-main-dark;
        border: 1px solid $color-neutral-grey-lighter;
        color: $color-text-white;

        .content-container {
            display: flex;
            margin: $container-margin;
            margin-bottom: 0;
            border-bottom: 1px solid $color-neutral-grey-lighter;

            .left-pane {
                width: 120px;
                margin-right: 10px;

                .form-check {
                    margin-bottom: 10px;
                    font-size:14px
                }
            }
        }

        .buttons-container {
            float: right;
            margin: 10px $container-margin 10px 0;

            button {
                margin-left: 10px;
            }
        }
    }
}