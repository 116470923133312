.title-with-risk-display {
    display: flex;
    align-items: center;
    font-size: 11px;
    font-weight: 700;

    .title-with-risk-display-title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 2px;
    }
    .title-with-risk-display-risk-wrapper {
        display: flex;
        align-items: center;
        margin-left: 5px;

        .title-with-risk-display-alert {
            margin-right: 10px;
        }
    }
}