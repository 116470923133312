@import 'utils/scss_variables.module.scss';

.modal-container.license-form-modal {
    max-height: 520px !important;
    text-align: center;
    padding: 30px;

    .license-icon {
        background-image: url('./ciscoLogo.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: 100%;
        height: 40px;
    }
    .license-title {
        font-size: 15px;
        font-weight: bold;
        text-transform: uppercase;
        margin: 20px 0;
    }
    .license-text-container {
        text-align: left;
        font-size: 9px;
        padding: 10px 15px;
        color: $color-text-white;
        border: 1px solid $color-neutral-grey-light;
        height: 300px;
        overflow-y: auto;

        > ol > li {
            padding-top: 15px;
        }
        .list-item {
            padding-left: 15px;
        }
        ol {
            counter-reset: item;
            padding-left: 0;

            ol {
                padding-left: 15px;
            }
        }
        li {
            display: block;

            &:before {
                content: counters(item, ".") ".   ";
                counter-increment: item;
            }
        }
    }
    button {
        float: right;
        margin-top: 20px;
    }
}