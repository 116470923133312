@import 'utils/scss_variables.module.scss';

.image-layers-wrapper {
    padding: 20px;

    .image-layer-item-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid $color-neutral-grey-light;
        border-radius: 4px;
        padding: 15px 20px;
        margin-bottom: 10px;
        cursor: pointer;

        &.is-safe {
            cursor: default;

            .layer-name-container .layer-name-wrapper .layer-name {
                color: $color-disabled;
                cursor: default;
            }
        }
        .layer-name-container {
            display: flex;
            align-items: center;
            width: calc(100% - 380px);
            margin-right: 10px;

            .icon {
                color: $color-neutral-grey;
            }
            .layer-name-wrapper {
                margin-left: 5px;
                max-width: 100%;

                .layer-name {
                    border: none;
                    outline: none;
                    font-size: 12px;
                    font-weight: bold;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 100%;
                    cursor: pointer;
                    background-color: inherit;
                    color: $color-text-white;
                }
            }
        }
    }
}