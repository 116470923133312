@import 'utils/scss_variables.module.scss';

.seccomp-assigned-pspp-container {
    font-size: 11px;

    .profile-name {
        font-weight: bold;
        color: $color-main-light;
    }
}

.seccomp-proriles-container {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 15px;
    background-color: $color-main-dark;

    .seccomp-proriles-header {
        .new-seccomp-button {
            width: 140px;
        }
    }
    .seccomp-proriles-content {
        margin-top: 10px;
        height: calc(100% - 30px);
        overflow-y: auto;

        .seccomp-policy-item {
            border: solid 1px $color-neutral-grey-light;
            margin-bottom: 15px;
            padding: 15px 10px;
            position: relative;

            .item-actions {
                display: none;
                position: absolute;
                top: 5px;
                right: 5px;

                .icon:not(.disabled) {
                    color: $color-main-light;
                }
            }
            .title {
                font-weight: bold;
                font-size: 11px;
            }
            .seccomp-assigned-pspp-container {
                margin-top: 5px;
            }

            &:hover {
                cursor: pointer;

                .item-actions {
                    display: flex;
                }
            }
        }
    }
}

$modal-padding: 25px;
$modal-width: 600px;

.modal-outer .modal-container.seccomp-item-modal {
    padding: $modal-padding;
    padding-bottom: 0;
    max-height: 620px;
    width: $modal-width;
    max-width: $modal-width;
    position: relative;
    
    .seccomp-item-title {
        font-size: 18px;
        font-weight: bold;
        width: calc(100% - 120px);
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .seccomp-item-actions {
        display: flex;
        position: absolute;
        top: 25px;
        right: 60px;

        .icon:not(.disabled) {
            color: $color-main-light;
        }
    }
    .seccomp-item-header {
        margin: 0 -#{$modal-padding};
        padding: 10px $modal-padding;
        border-bottom: 1px solid $color-neutral-grey-light;
    }
    .seccomp-item-content {
        padding-top: 20px;
        height: calc(100% - 75px);
        overflow-y: auto;
        font-size: 12px;
        
        pre {
            color: $color-text-white;
        }
    }
}