@import 'utils/scss_variables.module.scss';

.breadcrumbs-title-header {
    display: flex;
    align-items: center;
    padding: 15px 0 5px 0;
    
    a,
    a:hover {
        color: $color-main-light;
        text-decoration: none;
    }
    .icon {
        color: $color-main-light;
    }
    .title {
        font-size: 13px;
        font-weight: bold;
    }
    .breadcrumbs-container {
        display: flex;
        align-items: center;
        margin-left: 10px;

        .breadcrumb-container {
            font-size: 13px;
            display: flex;
            align-items: center;
            margin-right: 5px;
        }
    }
}