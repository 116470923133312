@import 'utils/scss_variables.module.scss';

.suggestion-item {
    border-bottom: 1px solid $color-neutral-grey-light;
    box-sizing: border-box;
    
    &:not(.is-open) {
        height: 74px;
    }
    &.is-open,
    &:hover {
        background-color: $color-hover;

        .suggestion-header .suggested-item-title-with-icon-wrapper .suggested-item-title-wrapper .select-suggestion {
            font-weight: bold;

            .ps-checkbox-title {
                font-weight: bold !important;
            }
        }
    }
    &.is-open {
        .suggestion-header .suggested-item-title-with-icon-wrapper .suggested-item-title-wrapper .select-suggestion .ps-checkbox-title {
            padding-top: 3px;
        }
    }
    &.above-selected {
        position: relative;
    }
    .suggestion-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .suggested-item-title-with-icon-wrapper {
            max-width: calc(100% - 150px);
            width: 100%;
            display: flex;
            align-items: center;

            .suggested-item-title-wrapper {
                padding: 20px 15px;
                max-width: calc(100% - 20px);

                .title-container {
                    .select-suggestion {
                        color: $color-text-white;
                        margin-bottom: 0px;
                    }
                }
                .affected-title {
                    font-size: 11px;
                    font-weight: bold;
                    color: $color-text-light;
                    margin-left: 38px;

                    &.critical {
                        color: $color-error;
                    }
                    span.affected-total {
                        font-weight: normal;
                        text-transform: uppercase;
                    }
                }
            }
        }
        .suggestion-title-actions-wrapper {
            display: flex;
            align-items: center;

            .header-display-item-container {
                margin-right: 5px;
            }
            .icon.expand-icon {
                cursor: pointer;
                color: $color-neutral-grey;
            }
        }
    }
    .suggested-item-content {
        margin: 0 15px;

        .suggestion-content-title {
            color: $color-text-light;
            font-size: 11px;
            font-weight: bold;
            text-transform: uppercase;
            margin-bottom: 5px;
        }
        .suggestion-content-label {
            color: $color-text-light;
            font-size: 9px;
            font-weight: bold;
            margin-top: 5px;
            margin-bottom: 5px;
        }
        .suggestion-content-value {
            font-size: 11px;
            color: $color-text-white;
        }
        hr.separator {
            border-color: $color-neutral-grey-light;
            margin: 0 0 15px 0;
        }
        .content-display-item {
            width: 49%;
            max-height: 160px;
            margin-right: 5px;

            .suggestion-content-sub-title {
                margin-bottom: 10px;
            }
            .names-container {
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 5px;

                .name-item-wrapper {
                    height: 26px;
                    margin: 0 5px 5px 0;
                    
                    .name-item {
                        color: $color-neutral-dark;
                        background-color: $color-neutral-blue;
                        height: 26px;
                        width: 80px;
                        max-width: 80px;
                        min-width: 80px;
                        border-radius: 12px;
                        font-size: 12px;
                        font-weight: bold;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        padding: 4px;
                        text-align: center;
                    }
                }
            }
        }
        .suggestion-stats-content {
            margin-bottom: 10px;
            display: flex;

            .stats-item {
                width: 50%;

                .time-period-display {
                    width: 100px;
                    display: flex;
                    flex-direction: column;

                    .time-item {
                        font-size: 11px;
                        font-weight: bold;
                        color: $color-text-white;

                        .icon {
                            margin-right: 5px;
                        }
                    }
                    .time-connector {
                        font-size: 11px;
                        color: $color-text-white;
                        align-self: center;
                    }
                }
                .affected-display {
                    .affected-item {
                        .affected {
                            font-size: 25px;
                            font-weight: bold;
                            color: $color-text-white;
                            margin-right: 3px;
                        }
                        .total {
                            font-size: 12px;
                            color: $color-text-white;
                        }
                    }
                    .affected-desctiption {
                        font-size: 11px;
                        color: $color-text-white;

                        &.is-link {
                            cursor: pointer;
                            
                            &:hover {
                                font-weight: bold;
                            }
                        }
                    }
                }
            }
        }
        .apply-button {
            margin-bottom: 10px;
        }
    }
}