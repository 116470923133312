@import 'utils/scss_variables.module.scss';

$loader-border-radius: 20px;

.line-loader-container {
    position: relative;
    height: 6px;
    background-color: $color-main-dark;
    border-radius: $loader-border-radius;

    &.small {
        height: 4px;

        .line-loader-filler {
            border-radius: 0;
            
            &.done {
                border-radius: 0;
            }
        }
    }
    .line-loader-filler {
        border-top-left-radius: $loader-border-radius;
        border-bottom-left-radius: $loader-border-radius;
        height: 100%;

        &.done {
            border-radius: $loader-border-radius;
        }
    }
    .line-loader-title {
        display: table;
        margin: auto;
        color: $color-text-white;
        font-size: 12px;
        padding-top: 10px;
    }
}