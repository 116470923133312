@import 'utils/scss_variables.module.scss';

$settings-table-height: 200px;

.table-settings-container {
    margin-bottom: 40px;
    
    .add-new-button {
        width: 150px;
    }
    .table-wrapper-new {
        .table-action-buttons-container {
            position: absolute;
            top: -42px;
            right: 0;
        }
        .table-container {
            .table {
                background: $color-main-dark;
                border: 1px solid $color-neutral-grey-lighter;

                .table-head {
                    .table-tr .table-th {
                        background-color: rgba($color-status-blue, 0.1);
                    }
                }
                .table-body {
                    height: 100%;
                    max-height: $settings-table-height;
    
                    .scrollbar-container {
                        overflow: visible !important;
                    }
                }
            }
        }
        .settings-row-actions {
            display: flex;
            justify-content: space-between;
            width: 100%;

            .icon:not(.disabled) {
                color: $color-main;
            }
        }
    }
    .add-icon-wrapper.with-border {
        background: $color-main-dark;
        border: 1px solid $color-neutral-grey-lighter;
    }
    .add-icon-wrapper.with-border {
        padding: 20px 10px;
    }
}