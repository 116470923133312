@import 'utils/scss_variables.module.scss';

.list-display-wrapper {
    .list-display-item-wrapper {
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px 0 30px;
        font-size: 14px;
        line-height: 16px;
        border-top: 1px solid $color-neutral-grey-light;

        &:not(.disabled) {
            cursor: pointer;
        }
        &:last-child {
            border-bottom: 1px solid $color-neutral-grey-light;
        }
        &.selected {
            background-color: $color-main;
        }
        &:hover:not(.selected):not(.disabled) {
            background-color: $color-hover;
        }
        .list-display-item {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: calc(100% - 20px);
        }
        .arrow-icon {
            color: $color-neutral-grey;
        }
    }
}