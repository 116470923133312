@import 'utils/scss_variables.module.scss';

$filter-field-width: 149px;

.ps-form.table-filters-form.runtime-connections-table-filter-form {
    .runtime-conn-logical-fields-wrapper {
        display: flex;
        background-color: $color-main-dark;
        border-radius: 4px;
        padding: 10px 5px;

        .ps-field-wrapper {
            &.ps-text-field-wrapper {
                input {
                    width: $filter-field-width;
                    background-color: $color-background;
                }
            }
            &.ps-clusters-namespaces-filter-field-wrapper {
                width: $filter-field-width;
    
                &.target-namespaces-filter {
                    width: 160px;
                }
                .selector-wrapper {
                    background-color: $color-background;
                }
            }
            .ps-field-label-wrapper .form-label {
                color: $color-text-medium;
            }
        }
        .ps-field-wrapper:not(:last-child) {
            margin-right: 5px;
        }
    }
    .ps-field-wrapper.ps-select-field-wrapper.logical-selector-field {
        margin-top: 15px;

        .selector-wrapper {
            width: 42px;

            .ps-single-select {
                .single-select__control {
                    border: none;
                    
                    .single-select__value-container {
                        padding: 0;
                        max-width: 100%;
                        
                        .value-content {
                            overflow: unset;
                            text-overflow: unset;
                        }
                    }
                    .single-select__indicators {
                        max-width: 20px;
                    }
                }
                .single-select__menu {
                    width: 80px;
                }
            }
            
        }
        
    }
}