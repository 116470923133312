@import 'utils/scss_variables.module.scss';

.risk-tag-wrapper {
    box-sizing: border-box;
    font-weight: 700;
    font-size: 11px;
    text-align: center;
    border: 1px solid transparent;
    display: inline-block;
    border-radius: 100px;
    width: 122px;
    height: 24px;
    padding: 2px 0;
}