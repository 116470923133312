@import 'utils/scss_variables.module.scss';

.policy-search-field.search-field-select {
    margin-left: 10px;
    
    .search-field-select__option {
        .item-title {
            font-size: 12px;
            font-weight: bold;
            margin-bottom: 10px;
            color: $color-text-white;
        }
        .item-labels {
            display: flex;
            flex-wrap: wrap;
    
            .item-label {
                font-size: 11px;
                font-weight: bold;
                color: $color-text-light;
                border-radius: 24px;
                border: solid 1px $color-neutral-grey-lighter;
                padding: 5px 8px;
                white-space: nowrap;
                margin: 0 5px 5px 0;
            }
        }
        &.search-field-select__option--is-focused,
        &.search-field-select__option--is-selected {
            background-color: rgba($color-status-blue, 0.1);
        }
    }
}