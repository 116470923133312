@import 'utils/scss_variables.module.scss';

.ci-policy-item-container {
    display: flex;
    justify-content: space-between;

    .ps-field-wrapper {
        width: 48%;
    }
    .dockerfile-scan-severity-icon {
        &.fatal {
            color: $color-error !important;
        }
        &.warn {
            color: $color-warning !important;
        }
        &.info {
            color: $color-main !important;
        }
    }
}