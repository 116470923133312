@import 'utils/scss_variables.module.scss';

$workload-icon-size: 30px;

.workload-cell-wrapper {
    display: flex;
    align-items: center;
    
    .workload-icon {
        width: $workload-icon-size;
        height: $workload-icon-size;
        min-width: $workload-icon-size;
        min-height: $workload-icon-size;
        color: $color-main-light;
        margin-right: 10px;
    }
}