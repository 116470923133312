@import 'utils/scss_variables.module.scss';

.cloud-account-serverless-page {
	.table-header-container {
		display: flex;
		align-items: center;
		margin-bottom: 10px;
		height: 33px;

		.create-account-actions-wrapper {
			> div {
				margin-right: 10px;
			}
		}
	}
	.table-wrapper-new {
		.table-action-buttons-container {
			width: 270px;
			position: absolute;
			top: -44px;
			right: 0;
		}
		.cloud-account-row-actions {
			display: flex;
			justify-content: space-around;
			width: 100%;

			.icon {
				color: $color-main;
			}
		}
	}
}

.modal-outer .modal-container.vulnerabilities-install-modal {
    top: 5%;
    max-height: 450px;
    width: 590px;

	.install-cluster-content .tab-content {
		padding-top: 10px;
		min-height: 225px;
	}
    button {
        margin-top: 40px;
        float: right;
    }
}

.modal-outer .modal-container.cloud-scanner-install-modal {
    max-height: 370px;
    width: 590px;

	&.is-large {
		max-height: 510px;
	}
	.modal-title {
		margin-bottom: 20px;
	}
	.provider-selectors-wrapper {
		display: flex;
		justify-content: space-between;
		margin-bottom: 20px;
	}
	textarea {
		height: 40px;
	}
	.external-id-copy {
		margin: 5px 0;
	}
}

.modal-outer .modal-container.modal-confirmation.serverless-account-delete-confirmation {
	&.is-large {
		max-height: 400px;
		width: 590px;	
	}
	.azure-delete-account-confirmation-message {
		div,
		ol li {
			margin-bottom: 10px;
		}
	}
}