@import 'utils/scss_variables.module.scss';

.deployments-deployers-page {
    .table-header-container {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .add-new-button {
            width: 110px;
            margin-right: 10px;
        }
    }
    .table-wrapper-new {
        .table-action-buttons-container {
            width: 270px;
            position: absolute;
            top: -44px;
            right: 0;
        }
        .deployers-row-actions {
            display: flex;
            justify-content: space-around;
            width: 100%;
            
            .icon {
                color: $color-main;
            }
        }
        .deployer-status-icon {
            color: $color-error;

            &.success {
                color: $color-success;
            }
        }
    }
}

.deployers-form {
    .deployment-scan-button-wrapper {
        margin-top: 20px;
        float: left;
        margin-right: 10px;
    }
}