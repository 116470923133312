.cis-scan-progress {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 150px;

    .scan-progress-bar {
        display: flex;
        flex-grow: 1;
        margin-right: 12px;
    }
    .scan-progress-percentage {
        margin-right: 6px;
        width: 30px;
    }
}