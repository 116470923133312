@import 'utils/scss_variables.module.scss';

.runtime-namespaces-page {
    .table-filters-container {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }
    .table-wrapper-new {
        .table-action-buttons-container {
            width: 270px;
            position: absolute;
            top: -44px;
            right: 0;
        }
        .namespace-protect-icon {
            color: $color-main;
        }
    }
}

$protect-modal-width: 590px;

.modal-outer .namespace-protect-modal.modal-container {
    background-color: $color-white;
    width: $protect-modal-width;
    max-height: 265px;

    .protect-header {
        font-size: 11px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;

        .icon {
            color: $color-main;
        }
    }
    .command-input {
        width: 100%;
        font-size: 11px;
        color: $color-grey-black;
        background-color: $color-grey-off-white;
        border: 1px solid $color-grey-lighter;
        outline: none;
        padding: 5px 15px;
    }
    .protect-message-container {
        display: flex;
        align-items: center;
        border-radius: 50px;
        max-width: 400px;
        margin-top: 20px;

        .icon {
            color: $color-main;
            margin-right: 5px;
        }
        .message {
            font-size: 10px;
            line-height: 10px;
            color: $color-grey-black;
            font-weight: 600;
        }
    }
    .action-button-wrapper {
        display: flex;
        justify-content: flex-end;
        margin-top: 30px;
    }
}