@import 'utils/scss_variables.module.scss';

.tab-licenses-content {
    padding: 20px;

    table {
        padding: 20px;
    
        tr:not(:last-child) {
            border-bottom: 1px solid $color-neutral-grey-lighter;
        }
        td {
            padding-right: 0;
            padding-left: 0;
        }
    }
}