@import 'utils/scss_variables.module.scss';

.ps-field-wrapper.ps-radio-description-field-wrapper {
    .ps-radio-desc-container {
        max-width: 500px;

        .radio-item-wrapper {
            border-top: 1px solid $color-neutral-grey-light;

            &.disabled {
                .radio-item-title .ps-radio {
                    cursor: not-allowed;
                }
            }
            &:hover:not(.disabled) {
                background-color: $color-hover;
            }
            &:last-child {
                border-bottom: 1px solid $color-neutral-grey-light;
            }
            .radio-item-title {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-right: 10px;

                .arrow-icon {
                    color: $color-neutral-grey;
                }
                .ps-radio {
                    display: block;
                    position: relative;
                    padding: 10px 5px;
                    cursor: pointer;
                    margin-bottom: 0;
                    width: calc(100% - 20px);
            
                    .ps-radio-text {
                        padding-left: 16px;
                        -webkit-box-decoration-break: clone;
                        box-decoration-break: clone;
                    }
                    input {
                        position: absolute;
                        opacity: 0;
                        cursor: pointer;
            
                        &:checked ~ .checkmark:after {
                            content: "";
                            position: absolute;
                            display: block;
                            top: 1px;
                            left: 1px;
                            width: 6px;
                            height: 6px;
                            border-radius: 50px;
                            background: $color-main-light;
                        }
                    }
                    .checkmark {
                        position: absolute;
                        top: 15px;
                        left: 5px;
                        height: 10px;
                        width: 10px;
                        background-color: inherit;
                        border-radius: 50px;
                        border: 1px solid $color-neutral-grey;
                    }
                }
            }
            .radio-item-description {
                font-size: 11px;
                margin: 0 22px;
                padding-bottom: 10px;
            }
        }
    }
}