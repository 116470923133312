@import 'utils/scss_variables.module.scss';

$item-padding: 15px;

.unorderd-policy-item-display-container {
    margin-bottom: 15px;

    &.marked {
        border: 2px solid $color-main;
    }
    .unorderd-policy-item-header-container {
        background-color: $color-main-dark;
        border: 1px solid $color-neutral-grey-light;
        position: relative;
        display: flex;
        align-items: center;
        padding: $item-padding;
        cursor: pointer;

        .unorderd-policy-item-name {
            width: 300px;
            color: $color-text-white;
            font-size: 11px;
        }
        .unorderd-policy-item-description {
            color: $color-text-light;
            font-size: 11px;
        }
        .unorderd-policy-item-actions {
            position: absolute;
            right: calc(#{$item-padding} + 30px);
            display: none;

            .icon {
                color: $color-neutral-grey;
            }
        }
        .unorderd-policy-item-open-toggle {
            position: absolute;
            right: $item-padding;
            color: $color-neutral-grey;
        }
        &:hover {
            .unorderd-policy-item-actions {
                display: flex;
            }
        }
    }
    .unorderd-policy-item-body-container {
        padding: $item-padding;
        background-color: $color-main-dark;
        border: 1px solid $color-neutral-grey-light;
        border-top: none;
    }
}