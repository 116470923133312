@import 'utils/scss_variables.module.scss';

.pod-form-container {
    .containers-list-field.with-link {
        .input-wrapper {
            position: relative;

            .link-to-image {
                position: absolute;
                top: 8px;
                right: 15px;

                .icon {
                    color: $color-main;
                }
            }
            input {
                padding-right: 35px;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }
}