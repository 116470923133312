@import 'utils/scss_variables.module.scss';

$affected-table-height: calc(100vh - 150px);

.modal-container.affected-connections-modal {
    right: 0;
    left: unset;
    width: 900px;

    .affected-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        a .icon-container {
            flex-direction: row-reverse;
    
            .icon-small-arrow-left {
                transform: rotate(180deg);
            }
        }
    }
    .table-wrapper-new .table-action-buttons-container {
        width: 100%;
    }
}