@import 'utils/scss_variables.module.scss';

.token-injection-status-wrapper {
    display: flex;
    align-items: center;

    .status-icon-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
    
        .status-icon {
            color: $color-grey;
    
            &.success {
                color: $color-success;
            }
            &.error {
                color: $color-warning;
            }
        }
    }
    .injection-message-wrapper {
        margin-left: 10px;
        max-width: 200px;
    }
}