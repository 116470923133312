@import 'utils/scss_variables.module.scss';

$install-tabs-height: 30px;

.deployments-clusters-page {
    .table-header-container {
        height: 33px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;

        .table-filters-form {
            margin-left: 10px;
        }
    }
    .table-wrapper-new {
        .table-action-buttons-container {
            width: 270px;
            position: absolute;
            top: -44px;
            right: 0;
        }
        .cluster-controller-name {
            display: flex;

            .cluster-controller-name-helm-tab {
                color: $color-grey-dark;
                border-left: 1px solid $color-grey-dark;
                margin-left: 5px;
                padding-left: 5px;
                height: 15px;
            }
        }
    }
}
.modal-outer .modal-container.install-modal {
    top: 5%;
    max-height: 735px;
    width: 590px;

    > button {
        float: right;
    }
    .install-cluster-container {
        .install-title {
            margin-bottom: 15px;
        }
        .install-cluster-content {
            height: 560px;

            .page-tabs-container {
                height: $install-tabs-height;
                margin-bottom: 15px;
            }
            .tab-content {
                overflow-y: auto;
                height: 505px;
            }
            .install-data-container {
                .instructions-item {
                    &.all-namespaces textarea {
                        height: 72px;
                    }
                    &.single-namespace > .item-title {
                        margin-top: 10px;
                    }
                    &.istio-set-image textarea {
                        height: 70px;
                    }
                    &.create-secret-instructions textarea {
                        height: 55px;
                    }
                }
            }
        }
    }
}
.modal-outer .modal-container.uninstall-modal {
    top: 5%;
    max-height: 360px;

    .uninstall-data-container {
        .uninstall-title {
            margin-bottom: 15px;
        }
        .instructions-item textarea {
            height: 52px;
        }
    }
    button {
        margin-top: 20px;
        float: right;
    }
}
.clusters-row-actions {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .icon {
        color: $color-main;
        cursor: pointer;
    }
}
.modal-outer .modal-container.upgrade-account-modal {
    width: 670px;
    max-height: 300px;
    text-align: center;
    
    .upgrade-account-title {
        font-weight: 700;
        font-size: 34px;
        line-height: 46px;
        color: $color-text-white;
        margin: 10px 0 40px 0;
        padding-bottom: 20px;
        border-bottom: 1px solid $color-neutral-grey-light;
    }
    .upgrade-account-description {
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        margin-bottom: 30px;
    }
}