@import 'utils/scss_variables.module.scss';

.trusted-signers-page-wrapper {
    .table-wrapper-new {
        .table-action-buttons-container {
            position: absolute;
            top: -45px;
            right: 0;
        }
        .signers-row-actions {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            .icon:not(.disabled) {
                color: $color-main;
            }
        }
    }
}