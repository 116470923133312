@import 'utils/scss_variables.module.scss';

html body {
    font-family: Inter, sans-serif;
    font-size: 14px;
    color: $color-text-white;
    background-color: $color-background;
    color-scheme: only dark;
    margin: 0;

    #root {
        height: 100vh;
    }

    a,
    a:hover {
        color: $color-main-light;
        text-decoration: underline;
    }

    .global-risk-color {
        &.critical {
            color: $color-risk-critical !important;
        }
        &.high {
            color: $color-risk-high !important;
        }
        &.medium {
            color: $color-risk-medium !important;

            &.text {
                color: $color-risk-medium-text !important;
            }
        }
        &.low {
            color: $color-risk-low !important;

            &.text {
                color: $color-risk-low-text !important;
            }
        }
        &.unknown {
            color: $color-risk-unknown !important;
        }
        &.approved {
            color: $color-risk-approved !important;
        }
        &.no_risk {
            color: $color-risk-no-known !important;
        }
    }
}