.log-management-form-wrapper {
    .events-to-forward-wrapper {
        display: flex;
    
        .field-container {
            margin-right: 10px;
        }
    }
    .url-label-container .form-label {
        display: flex;
    }
    .events-forwarding-test-connection-button {
        float: left;
        margin-right: 10px;
        position: relative;

        .test-connection-button-error {
            position: absolute;
            width: 550px;
        }
    }
}