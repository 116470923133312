@import 'utils/scss_variables.module.scss';

.ps-field-wrapper.ps-text-field-wrapper {
    position: relative;
    
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }

    input {
        width: 100%;
        height: 36px;
        color: $color-text-white;
        outline: none;
        border: 1px solid $color-neutral-grey-light;
        background-color: inherit;
        border-radius: 2px;
        padding: 0 10px;
        
        &:disabled {
            color: $color-disabled;
        }
        &::placeholder {
            color: $color-text-light;
        }
    }
    .password-icon {
        position: absolute;
        top: 8px;
        right: 8px;
        cursor: pointer;
        color: $color-main;
    }
    .field-units {
        position: absolute;
        top: 8px;
        right: 8px;
        color: $color-grey;
    }
    &.has-label .password-icon {
        top: 33px;
    }
    &.has-label .field-units {
        top: 33px;
    }
}