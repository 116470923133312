@import 'utils/scss_variables.module.scss';

.close-button {
    position: absolute;
    right: 20px;
    color: $color-text-white;
    cursor: pointer;

    &:hover:not(:disabled),
    &:focus:not(:disabled) {
        color: $color-text-white;
    }
}