@import 'utils/scss_variables.module.scss';

$box-size: 9px;

.ps-checkbox-wrapper {
    .ps-checkbox {
        display: inline-flex;
        align-items: center;
        position: relative;
        padding-left: 15px;
        cursor: pointer;
    
        &:not(.with-title) {
            width: $box-size;
        }
        .inner-checkbox-wrapper {
            position: relative;

            .checkmark {
                position: absolute;
                top: 7px;
                left: 0;
                height: $box-size;
                width: $box-size;
                background-color: inherit;
                border: 1px solid $color-neutral-grey;
        
                &:after {
                    content: "";
                    position: absolute;
                    display: none;
                    left: 1px;
                    top: -1px;
                    width: 5px;
                    height: 7px;
                    border: solid $color-main-light;
                    border-width: 0 2px 2px 0;
                    transform: rotate(45deg);
                }
                &.half-selected:after {
                    top: 2px;
                    left: -1px;
                    height: 3px;
                    width: 8px;
                    border: none;
                    background-color: $color-main-light;
                    transform: rotate(0deg);
                    display: block;
                }
            }

            input {
                opacity: 0;
                cursor: pointer;
                margin-left: 9px;
        
                &:checked ~ .checkmark {
                    &:after {
                        display: block;
                    }
                }
                
            }
        }
        &.small {
            .checkmark {
                top: 5px;
            }
        }
        &.disabled {
            cursor: not-allowed;
            color: $color-disabled;
    
            input {
                cursor: not-allowed;
            }
            .checkmark {
                border-color: $color-disabled;

                &:after {
                    border-color: $color-disabled;
                }
            }
        }
    }
}
