@import 'utils/scss_variables.module.scss';

$inner-padding: 20px;

.user-menu {
    background-color: $color-background;
    height: $top-nav-height;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;

    .user-round-icon {
        width: 40px;
        height: 40px;
        box-sizing: border-box;
        border-radius: 50%;
        background-color: $color-success;
        color: $color-neutral-dark;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        font-size: 12px;
        font-weight: 700;
        z-index: $top-layer-z-index;
    }
    .user-data-container {
        margin-left: 20px;
        color: $color-text-white;

        .user-role-container {
            font-size: 11px;
            color: $color-text-light;
            font-weight: 400;
            white-space: nowrap;
        }
        .user-menu-container {
            .user-menu-btn {
                font-size: 13px;
                font-weight: bold;
                white-space: nowrap;
            }
            .dropdown-menu {
                width: 280px;
                transform: translate3d(-160px, 46px, 0px) !important;
                z-index: $top-layer-z-index;
                background-color: $color-background;
                border: 1px solid $color-neutral-grey-light;

                &::before {
                    content: '';
                    position: absolute;
                    top: -7px;
                    right: 22px;
                    display: inline-block;
                    border-right: 7px solid transparent;
                    border-bottom: 7px solid $color-neutral-grey-light;
                    border-left: 7px solid transparent;
                }
                &::after {
                    content: '';
                    position: absolute;
                    top: -6px;
                    right: 23px;
                    display: inline-block;
                    border-right: 6px solid transparent;
                    border-bottom: 6px solid $color-background;
                    border-left: 6px solid transparent;
                }
                .user-menu-content {
                    .account-data-wrapper {
                        min-height: 70px;
                        padding: $inner-padding;
                        border-bottom: 1px solid $color-neutral-grey-light;

                        .account-type {
                            font-weight: 500;
                            font-size: 10px;
                            line-height: 14px;
                            color: $color-text-light;
                            margin-bottom: 9px;
                            text-transform: uppercase;
                        }
                        .usage-bar-container {
                            display: flex;
                            align-items: center;
                            font-weight: 500;
                            font-size: 10px;
                            line-height: 14px;
                            margin-bottom: 8px;
                            color: $color-text-white;

                            .line-loader-container {
                                width: 100%;
                            }
                            .usage-bar-container-title {
                                min-width: 42px;
                                margin-right: 6px;
                            }
                            .usage-bar-container-counts {
                                width: 45px;
                                margin-left: 6px;
                                
                                text-align: end;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                            }
                        }
                        .upgrade-link {
                            font-weight: 400;
                            font-size: 10px;
                            line-height: 14px;
                            color: $color-main-light;
                        }
                    }
                    .sign-out-title-icon {
                        padding: $inner-padding;

                        .icon {
                            color: $color-error;
                        }
                    }
                }
            }
        }
    }
}