@import 'utils/scss_variables.module.scss';

$multi-select-indicator-width: 32px;
$multi-select-indicator-icon-size: 13px;

.external-ca-form-container {
  .ps-multi-select{
    .ps-tooltip {
      width: 200px;
      a {
        color: white;
        font-weight: bold;
      }
    }
  }
  
  .ps-multi-select {
    &.small {
      .multi-select__indicators {
        max-width: $multi-select-indicator-width;
      
        .multi-select__indicator-separator {
          display: none;
        }
      
        .multi-select__dropdown-indicator {
          padding: 0;
          right: 4px;
        
          svg {
            height: $multi-select-indicator-icon-size;
            width: $multi-select-indicator-icon-size;
            color: $color-main;
          }
        }
      
        .multi-select__clear-indicator {
          padding: 0;
        
          svg {
            height: $multi-select-indicator-icon-size;
            width: $multi-select-indicator-icon-size;
            color: $color-main;
          }
        }
      }
    }
  
    .multi-select__control {
      .multi-select__placeholder {
        color: $color-grey;
      }
    
      .multi-select-value {
        position: absolute;
        left: 5px;
        display: flex;
        align-items: center;
        flex-direction: row;
        color: $color-grey-black;
        max-width: calc(100% - 7px);
      
        &.disabled {
        
          color: $color-grey;
          cursor: not-allowed;
        
          .icon {
            color: $color-grey;
          }
        }
      
        .icon {
          margin-right: 5px;
        }
      
        .value-content {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  
    .multi-select-option {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: $color-grey-black;
      padding: 10px;
      margin: 5px;
      cursor: pointer;
    
      .icon {
        color: $color-main;
        margin-right: 5px;
      }
    
      &:not(:last-child) {
        border-bottom: 1px solid $color-neutral-grey-lighter;
      }
    
      &:hover:not(.disabled),
      &:focus:not(.disabled) {
        background-color: $color-blue-light;
      }
    
      &.disabled {
        color: $color-grey;
        cursor: not-allowed;
      
        .icon {
          color: $color-grey;
        }
      }
    }
  }
}