@import 'utils/scss_variables.module.scss';

.base-grouped-policy-wrapper {
    .base-grouped-policy {
        .policies-header {
            display: flex;
            position: relative;

            .form-buttons-container {
                display: flex;
            }
            .history-container {
                position: absolute;
                right: 120px;
                background-color: $color-background;
            }
            .expand-collapse-wrapper {
                display: flex;
                margin-left: 15px;
            }
        }
        .policies-content {
            position: relative;
            overflow-y: auto;
            max-height: calc(100vh - 2 * #{$main-content-padding} - #{$top-nav-height} - 48px);

            .policies-content-overlay {
                position: absolute;
                background-color: $color-grey;
                opacity: 0.4;
                width: 100%;
                height: 100%;
                z-index: 1;
            }
        }
    }
    .policy-buttons {
        display: flex;
        position: absolute;
        top: 4px;
        right: 0;
        background-color: $color-background;

        button {
            margin-right: 10px;
            
            .icon-container {
                .icon,
                .icon-title {
                    color: $color-text-dark;
                }
            }
        }
    }
}