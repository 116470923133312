.acknowledge-vulnerabilities-form {
    display: flex;
    align-items: center;

    .acknowledge-vulnerabilities-range-select {
        width: 115px;
        margin: 0 10px;
        z-index: 2;
    }
}
.acknowledged-vulnerability-status-display {
    .status-indicator-wrapper {
        font-weight: bold;
    }
}