@import 'utils/scss_variables.module.scss';

$wizard-width: 640px;
$wizard-height: 520px;
$wizard-steps-width: 160px;
$wizard-content-padding: 20px;

.modal-container.confirmation-modal {
    padding: 0 !important;
    top: 5% !important;
    width: $wizard-width !important;
    min-width: $wizard-width !important;
    max-height: $wizard-height !important;

    .close-button {
        top: 20px;
    }
}
.confirmation-wizard {
    width: 100%;
    height: 100%;
    display: flex;

    .wizard-steps-wrapper {
        background-color: $color-main-dark;
        border-right: 1px solid $color-neutral-grey-light;
        width: $wizard-steps-width;
        padding: 40px 20px 40px 30px;

        .wizard-steps {
            border-left: 1px dotted $color-neutral-grey-light;

            .step-item {
                display: flex;
                align-items: center;
                margin-left: -15px;

                &:not(:last-child) {
                    margin-bottom: 30px;
                }
                &.active {
                    .step-title {
                        color: $color-text-white;
                    }
                    .step-icon {
                        background-color: $color-main-medium;
                        color: $color-text-white;
                    }
                }
                &.done {
                    cursor: pointer;

                    .step-icon {
                        background-color: $color-success;
                    }
                }
                .step-icon {
                    width: 30px;
                    height: 30px;
                    border-radius: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    font-size: 11px;
                    background-color: $color-main;
                    color: $color-text-dark;
                }
                .step-title {
                    font-weight: bold;
                    font-size: 11px;
                    color: $color-text-light;
                    margin-left: 10px;
                    max-width: 84px;
                    overflow: hidden;
                }
            }
        }
    }
    .wizard-content {
        width: calc(100% - #{$wizard-steps-width});
        overflow-y: auto;
        padding-bottom: $wizard-content-padding;

        &.full-width {
            width: 100%;
        }
        .wizard-step-wrapper {
            box-sizing: border-box;
            min-height: calc(#{$wizard-height} - #{$wizard-content-padding} - 50px);
            padding: 40px 30px;

            .wizard-title {
                font-weight: bold;
                font-size: 26px;
                margin-bottom: 10px;
            }
            .wizard-description {
                margin-bottom: 28px;
            }
        }
        .step-button {
            float: right;
            margin-right: 20px;

            &.submit {
                background-color: $color-main;
                color: $color-white;
                border: none;
            }
        }
    }
    //------ utils css: ------
    .confirmation-wizard-text {
        font-size: 11px;
    }
    .confirmation-wizard-inner-title {
        font-weight: bold;
        font-size: 12px;
        margin-bottom: 5px;
    }
    .confirmation-wizard-info-message {
        display: flex;
        align-items: center;
        margin: 5px 0;

        .icon {
            color: $color-error;
            margin-right: 5px;
        }
        .info-message {
            font-weight: bold;
            font-size: 10px;
        }
    }
    .confirmation-wizard-notification-message {
        font-size: 11px;
        padding: 15px;
        background-color: $color-alpha-red;
    }
    .confirmation-wizard-list {
        margin: 15px 0;

        .link-wrapper {
            font-size: 11px;
            display: flex;
            align-items: center;
            margin-bottom: 10px;

            .item-bullet {
                background-color: $color-main-light;
                width: 4px;
                height: 4px;
                border-radius: 50px;
                margin-right: 9px;
            }
            .item-text-wrapper {
                width: calc(100% - 15px);

                .item-link {
                    font-weight: bold;
                    margin-right: 5px;
                    cursor: pointer;
                    text-decoration: underline;
                }
            }
        }
    }
    .confirmation-wizard-section-separator {
        margin: 20px 0;
        border-color: $color-neutral-grey-light;
    }
}
