@import 'utils/scss_variables.module.scss';

.modal-container.command-modal {
    .command-form {
        position: relative;

        .form-fields-wrapper {
            height: 70px;
            
            textarea {
                height: 60px;
            }
        }
        button {
            float: right;
            margin-top: 0;
        }
        
    }
    .command-results {
        clear: both;
        min-height: 150px;
        height: calc(100% - 280px);

        .actions-container {
            display: flex;

            .icon-container {
                margin-right: 10px;
            }
        }
        .results-display-container {
            border: solid 1px $color-neutral-grey-light;
            margin: 10px 0;
            padding: 20px;
            height: calc(100% - 60px);
            overflow: auto;

            .result-item {
                margin-bottom: 10px;
                font-size: 11px;
                color: $color-text-white;

                .title {
                    color: $color-text-light;
                    font-weight: bold;
                    font-size: 11px;
                    margin-bottom: 0;
                }
                p {
                    margin-bottom: 0;

                    &.code-text {
                        white-space: pre;
                        font-family: monospace;
                        color: $color-text-light;
                    }
                }
            }
        }
    }
    .close-btn {
        float: right;
    }
}