@import 'utils/scss_variables.module.scss';

$margin-adjustment: -40px;
$versions-list-width: 250px;
$table-height: 100%;

.policy-history-container {
    margin: -50px $margin-adjustment;
    height: calc(100% - #{$margin-adjustment});

    .title-container {
        padding: 30px;
        box-shadow: 0 0 17px 0 rgba(55, 72, 95, 0.23);
        z-index: 1;
        position: relative;

        .sub-title {
            span {
                font-weight: bold;
                margin-left: 5px;
            }
        }
        .close-button {
            top: 50px;
        }
    }
    .version-content-container {
        height: calc(100% - 117px);
        display: flex;

        .versions-list-container {
            width: $versions-list-width;
            box-shadow: -8px 12px 43px 0 rgba(55, 72, 95, 0.27);
            z-index: 1;
            position: relative;
            overflow-y: auto;

            .table-wrapper-new .table-action-buttons-container {
                display: none;
            }
        }
        .version-display-container {
            background-color: $color-background;
            width: calc(100% - #{$versions-list-width});
            padding: 20px;

            .restore-btn {
                float: right;
                margin-bottom: 15px;
            }
            .selected-data-wrapper {
                height: calc(100% - 25px);
                overflow-y: auto;
                clear: both;
            }
        }
    }
}