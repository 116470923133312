@import 'utils/scss_variables.module.scss';

$calendar-font-size: 11px;

.ps-field-wrapper.ps-date-field-wrapper {
    .date-input {
        width: 100px;
    }
    &.full-width .date-input {
        width: 100%;
    }
    .date-input {
        width: 100%;
        height: 36px;
        outline: none;
        border: 1px solid $color-neutral-grey-light;
        border-radius: 2px;
        padding: 0 10px;
        background-color: transparent;
    }
    .dropdown-menu {
        background-color: $color-main-dark;
        border: 1px solid $color-neutral-grey-lighter;
        color: $color-text-white;
        padding: 10px 15px 0 15px;
    }
}

