@import 'utils/scss_variables.module.scss';

$icon-size: 14px;

.test-connection-button-wrapper {
    .test-connection-button {
        &.error {
            border-color: $color-error;

            &:hover {
                border-color: $color-error;
            }
            .icon-container:not(.disabled) {
                .icon {
                    color: $color-error;
                }
                .icon-title {
                    color: $color-error;
                }
            }
        }
        .icon-container {
            padding: 0;
    
            .icon {
                width: $icon-size;
                height: $icon-size;
                min-width: $icon-size;
                min-height: $icon-size;
            }
            &:not(.disabled) {
                .icon {
                    color: $color-main-light;
                }
                .icon-title {
                    color: $color-main-light;
                }
            }
        }
    }
    .test-connection-button-error {
        color: $color-error;
        font-size: 10px;
        font-weight: bold;
        margin-top: 10px;
        margin-bottom: 10px;
    }
}