@import 'utils/scss_variables.module.scss';

$sidebar-margin-size: 20px;

.sidebar {
    z-index: $top-layer-z-index;
    box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.4);
    width: $side-nav-width-small;
    height: 100vh;
    background-color: $color-main-dark;
    white-space: nowrap;
    transition: width .5s;

    .sidepar-menu-wrapper {
        height: calc(100vh - 115px);
        overflow-y: auto;

        .navigation-items {
            flex-wrap: nowrap;
        }
        .menu-title {
            text-transform: uppercase;
            color: $color-text-light;
            font-size: 9px;
            margin: 15px;
    
            &.admin-title {
                margin-top: 40px;
            }
        }
        .navigation-item {
            text-decoration: none;
            display: flex;
            align-items: center;
            padding: 10px 0 10px $sidebar-margin-size;
            height: 42px;
            box-sizing: border-box;
    
            .icon {
                color: $color-text-light;
            }
            .navigation-item-title {
                display: none;
                margin-left: 10px;
                font-size: 13px;
                color: $color-text-light;
           }
           .navigation-item-beta {
                font-style: italic;
                font-weight: 350;
                font-size: 10px;
                line-height: 14px;
                color: $color-grey;
                padding-left: 5px;
           }
            &.disabled {
                color: $color-grey-dark;
                cursor: not-allowed;
                
                .icon,
                .navigation-item-title {
                    color: $color-grey-dark;
                }
            }
            &.active,
            &:hover:not(.active):not(.disabled) {
                background-color: $color-main;

                .icon,
                .navigation-item-title {
                    color: $color-main-light;
                }
            }
        }
    }
    .app-icon-container {
        height: $top-nav-height;
        position: relative;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 23px auto 15px auto;

        .app-icon {
            cursor: pointer;
            background: url(./icons/app-logo-mini.svg);
            background-size: contain;
            background-repeat: no-repeat;
            height: 100%;
            width: 38px;
            transform: translate(5px, 9px);

            &.open {
                background-image: url(./icons/app-logo.svg);
                width: 150px;
                transform: translate(-8px, 9px);
            }
        }
        .close-menu-icon {
            color: $color-white;
            position: absolute;
            top: 18px;
            right: 6px;
        }
    }
    &.open {
        width: $side-nav-width-large;
        
        .navigation-item .navigation-item-title {
            display: inline;
        }
    }
}