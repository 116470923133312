@import 'utils/scss_variables.module.scss';

$step-icon-size: 50px;
$inner-step-margin: 10px;
$form-left-border-location: calc(#{$step-icon-size} / 2 + #{$inner-step-margin});

.wizard-content {
    .wizard-general-alert {
        margin-bottom: 50px;

        .icon {
            color: $color-error;
        }
    }
    .wizard-step {
        padding-bottom: 10px;
        
        .step-icon-container {
            background-color: $color-main;
            width: $step-icon-size;
            height: $step-icon-size;
            border-radius: 50px;
            padding: 14px;
            position: absolute;

            &:not(.disabled) {
                cursor: pointer;
            }
            .icon {
                color: $color-neutral-dark;
            }
        }
        .step-content {
            margin-left: calc(#{$step-icon-size} + #{$inner-step-margin});
            
            .step-index {
                font-size: 12px;
                font-weight: bold;
                color: $color-text-light;
            }
            .step-title {
                font-weight: bold;
                font-size: 26px;
                display: inline-block;
                color: $color-text-light;
            }
            .step-form {
                border-left: 1px dotted $color-grey-black;
                padding-bottom: 40px;
                padding-left: $form-left-border-location;
                margin-left: calc(-1 * #{$form-left-border-location});

                .step-buttons-wrapper {
                    margin-top: 20px;

                    button {
                        margin-right: 10px;
                    }
                }
            }
        }

        &.last:not(.active) .step-form {
            border-left: none;
        }
        &.active {
            .step-icon-container {
                background-color: $color-main-medium;

                .icon {
                    color: $color-white;
                }
            }
            .step-title {
                cursor: pointer;
                margin-bottom: 18px;
                color: $color-text-white;
            }
        }
        &.done {
            .step-icon-container {
                background-color: $color-success;
            }
            .step-title:not(.disabled) {
                cursor: pointer;
                color: $color-text-light;
            }
        }
    }
}