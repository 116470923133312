@import 'utils/scss_variables.module.scss';

$function-icon-size: 20px;

.function-cell-wrapper {
  display: flex;
  align-items: center;

  .function-icon {
    width: $function-icon-size;
    height: $function-icon-size;
    min-width: $function-icon-size;
    min-height: $function-icon-size;
    color: $color-main-light;
    margin-right: 10px;
  }
}