@import 'utils/scss_variables.module.scss';

.double-pane-display-wrapper {
    display: flex;
    background-color: $color-background-medium;

    > div {
        padding: 0 30px 30px 30px;
        width: 50%;

        &:first-of-type {
            border-right: 1px solid $color-neutral-grey-light;
        }
    }
}