@import 'utils/scss_variables.module.scss';

$arrow-size: 20px;

.back-route-button-wrapper {
    .back-route-button {
        color: $color-main-light;
        display: inline-flex;
        align-items: center;
        font-size: 18px;
        line-height: 18px;
        cursor: pointer;
        margin-bottom: 24px;
    
        .icon {
            margin-right: 14px;
            height: $arrow-size;
            width: $arrow-size;
        }
    }
}