@import 'utils/scss_variables.module.scss';

.violations-filter-wrapper {
    .selector-wrapper {
        display: flex;
        align-items: center;

        .vulnerabilities-ignore-toggle {
            margin: 0;
        }
        .ps-dropdown {
            .dropdown-menu {
                min-width: 120px;
                padding: 0;
                border-radius: 2px;
                background-color: $color-background;
                border: 1px solid $color-neutral-grey-light;
                color: $color-text-white;
        
                &:focus {
                    outline: none;
                }
                .multiselect-content-container {
                    padding: 10px;
                    
                    .multiselect-item-container {
                        margin-left: 10px;
                    }
                }
            }
            .select-indicator {
                display: flex;
                align-items: center;
                margin-left: 7px;

                .open-menu-icon {
                    color: $color-neutral-grey;
                }
                .indicator-container {
                    display: flex;
                    
                    .indicator-item {
                        width: 6px;
                        height: 6px;
                        border-radius: 5px;
                        border: 1px solid $color-main;
        
                        &:not(:last-child) {
                            margin-right: 5px;
                        }
                    }
                }
            }
        }
    }
}