@import 'utils/scss_variables.module.scss';

.display-section-wrapper {
    overflow: hidden;
    
    .section-title-wrapper {
        margin: 20px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        &.with-padding {
            padding: 20px 0;
        }
        &:not(.hide-underline) {
            border-bottom: 1px solid $color-neutral-grey-light;
        }
        .arrow-icon {
            color: $color-neutral-grey;
        }
        .section-title {
            font-weight: 700;
            font-size: 12px;
        }
    }
}