@import 'utils/scss_variables.module.scss';

.ps-field-wrapper.ps-select-field-wrapper {
    .selector-wrapper {
        position: relative;
        
        .single-select__control {
            border-radius: 2px;
        }
        .single-select__menu {
            z-index: 2;
        }
    }
}