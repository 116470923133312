@import 'utils/scss_variables.module.scss';

.cis-severity-wrapper {
    display: flex;
    align-items: center;

    .icon {
        margin-right: 5px;
    }
    &.fatal {
        color: $color-error;
    }
    &.warning {
        color: $color-warning;
    }
    &.info {
        color: $color-status-blue;
    }
}