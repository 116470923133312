@import 'utils/scss_variables.module.scss';

$single-select-indicator-width: 32px;
$single-select-indicator-icon-size: 13px;

.ps-single-select {
    .single-select__indicators {
        svg {
            color: $color-neutral-grey;
        }
    }
    &.small {
        .single-select__indicators {
            max-width: $single-select-indicator-width;

            .single-select__indicator-separator {
                display: none;
            }
            .single-select__dropdown-indicator {
                padding: 0;
                right: 4px;

                svg {
                    height: $single-select-indicator-icon-size;
                    width: $single-select-indicator-icon-size;
                }
            }
            .single-select__clear-indicator {
                padding: 0;

                svg {
                    height: $single-select-indicator-icon-size;
                    width: $single-select-indicator-icon-size;
                }
            }
        }
    }
    .single-select__control {
        border: 1px solid $color-neutral-grey-light;

        &:hover {
            border-color: $color-neutral-grey-light;
        }
        .single-select__placeholder {
            color: $color-text-light;
        }
        .single-select-value {
            position: absolute;
            left: 5px;
            display: flex;
            align-items: center;
            flex-direction: row;
            max-width: calc(100% - 7px);
            color: $color-text-white;

            &.disabled {
                color: $color-disabled;
                cursor: not-allowed;

                .icon {
                    color: $color-disabled;
                }
            }
            .icon {
                margin-right: 5px;
            }
            .value-content {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
        .single-select__input-container {
            color: $color-text-white;
        }
    }
    .single-select__menu {
        background-color: $color-background;
        border: 1px solid $color-neutral-grey-light;

        .single-select-option {
            display: flex;
            flex-direction: row;
            align-items: center;
            color: $color-text-light;
            padding: 10px;
            margin: 5px;
            cursor: pointer;

            .icon {
                margin-right: 5px;
            }

            &:hover:not(.disabled) {
                background-color: $color-hover;
            }
            &:not(:last-child) {
                border-bottom: 1px solid $color-neutral-grey-light;
            }
            &.disabled {
                color: $color-disabled;
                cursor: not-allowed;

                .icon {
                    color: $color-disabled;
                }
            }
        }
    }
}