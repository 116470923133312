@import 'utils/scss_variables.module.scss';

$item-left-margin: 10px;
$group-header-height: 55px;
$item-height: 77px;
$group-reorder-icon-size: 30px;
$group-open-icon-size: 30px;

.policy-rules {
    padding: 10px 0;

    .top-panel-rules,
    .bottom-panel-rules {
        margin: 20px 0;
    }
    .main-panel-rules {
        .policy-group-container {
            .policy-group-header {
                height: $group-header-height;
                box-shadow: 0px 5px 10px rgba(34, 43, 54, 0.11);
                border: 1px solid $color-neutral-grey-light;
                background-color: $color-background-medium;
                display: flex;
                align-items: center;
                font-size: 11px;
                position: relative;

                .arrow-icon {
                    color: $color-neutral-grey;
                }
                .header-title {
                    font-weight: bold;
                    width: 215px;
                    height: 100%;
                    line-height: $group-header-height;

                    .read-only-name {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    .edit-name {
                        height: 22px;
                        width: 100%;
                        padding: 2px 5px;
                    }
                }
                .header-content {
                    padding: 0 20px;
                    height: 100%;
                    line-height: $group-header-height;

                    span {
                        font-weight: bold;
                    }
                }
                .group-actions-menu-container {
                    position: absolute;
                    top: 5px;
                    right: 5px;
                    display: none;
                    color: $color-neutral-grey;

                    .dropdown-menu {
                        background-color: $color-background;
                        border: 1px solid $color-neutral-grey-light;
        
                        > div:hover {
                            background-color: $color-hover;
                        }
                    }
                }
                &:hover .group-actions-menu-container  {
                    display: block;
                }
            }
            .policy-group-content-container {
                .policy-group-content {
                    width: 100%;
                    margin-bottom: 15px;

                    &.with-background {
                        padding-top: 10px;
                        background-color: $color-background-medium;
                        border: 1px solid $color-neutral-grey-light;
                    }
                    .rule-container {
                        display: flex;
                        align-items: center;
                        margin-bottom: 15px;

                        .rule-content-container {
                            width: 100%;
                            margin-right: 15px;
                        }
                        .group-reorder-icon-container {
                            height: $item-height;
                            width: $group-reorder-icon-size;
                            display: flex;
                            align-items: center;

                            .group-reorder-icon {
                                color: $color-white;
                                width: $group-reorder-icon-size;
                                height: $group-reorder-icon-size;
                                min-width: $group-reorder-icon-size;
                                min-height: $group-reorder-icon-size;
                            }
                            &:hover,
                            &.is-dragged {
                                .group-reorder-icon {
                                    color: $color-main-light;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
