@import 'utils/scss_variables.module.scss';

.deployments-pod-templates-page {
    .table-header-container {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .add-new-button {
            margin-right: 10px;
        }
    }
    .table-wrapper-new {
        .table-action-buttons-container {
            width: 270px;
            position: absolute;
            top: -44px;
            right: 0;
        }
        .pod-templates-inner-table {
            width: 100%;
            table-layout: fixed;
        
            th, td {
                color: $color-text-white;
                padding: 5px !important;
                border-top: none !important;
                border-color: $color-neutral-grey-light;
                border-width: 1px;
            }
            th {
                text-transform: uppercase;
                font-size: 9px;
            }
            td {
                font-size: 10px;
            }
        }
        .pod-row-actions {
            display: flex;
            justify-content: space-around;
            width: 100%;
            
            .icon {
                color: $color-main;
            }
        }
    }
}