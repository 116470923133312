$tab-padding: 30px;

.function-role-policies {
    .empty-policy-role-wrapper {
        padding: $tab-padding;
        padding-top: 0;
    }
    .selected-role-wrapper {
        margin: $tab-padding;
        margin-top: 0;

        .selected-role-title {
            display: flex;
            align-items: center;
        }
    }
}