@import 'utils/scss_variables.module.scss';

.function-details-tab-wrapper{
    .risk-reasons-wrapper {
        .risk-reason {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
    
            .reason-text {
                margin-left: 5px;
                font-size: 12px;
                line-height: 15px;
    
                .risky-role {
                    cursor: pointer;
                    text-decoration: underline;
                    color: $color-main;
                }
            }
        }
        .empty-risk-reasons {
            font-size: 12px;
            line-height: 9px;
            margin-bottom: 10px;
        }
    }
    .key-value-list-wrapper {
        margin-bottom: 30px;
    }
}