@import 'utils/scss_variables.module.scss';

.no-policies-wrapper {
    box-shadow: 0px 3px 19px #000000;
    background-color: $color-background-light2;
    padding: 80px;
    text-align: center;
    margin: 20px 15px;
    
    .no-policies-item {
        display: inline-block;
        
        .main-title {
            font-size: 34px;
            font-weight: bold;
            margin-bottom: 15px;
        }
    }
}