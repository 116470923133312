@import 'utils/scss_variables.module.scss';

$cd-scan-resources-table-height: calc(100vh - 320px);

.cd-scan-page {
    .table-header-container {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }
    .table-wrapper-new {
        .table-action-buttons-container {
            width: 270px;
            position: absolute;
            top: -44px;
            right: 0;
        }
    }
}
.cd-scan-summary-container {
    display: flex;

    .count-icon-wrapper .icon {
        color: $color-main;

        &.high {
            color: $color-error;
        }
        &.medium {
            color: $color-warning;
        }
    }  
}
.cd-scan-resources-modal {
    .sub-title {
        font-size: 14px;

        span {
            font-weight: bold;
        }
    }
    .cd-scan-resources-header {
        display: flex;
        font-size: 11px;
        margin: 30px 0 10px 0;
    
        > :not(:last-child) {
            margin-right: 50px;
        }
        .label {
            font-weight: bold;
            color: $color-text-white;
        }
        .value {
            margin-top: 3px;
            color: $color-text-light;
        }
    }
    .table-wrapper-new {
        .table-action-buttons-container {
            width: 270px;
            position: absolute;
            top: -44px;
            right: 0;
        }
        .table .table-body {
            height: calc(100vh - #{$top-nav-height} - 350px);

            .cd-scan-resources-wrapper {
                font-size: 12px;
                display: flex;
                align-items: center;
            
                div {
                    width: 50%;
            
                    &.icon-container {
                        color: $color-main;
            
                        &.is-secret {
                            color: $color-error;

                            .icon {
                                color: $color-error;
                            }

                            &.is-no-risk {
                                color: $color-success;

                                .icon {
                                    color: $color-success;
                                }
                            }
                        }
                        &.high {
                            color: $color-error;
            
                            .icon {
                                color: $color-error;
                            }
                        }
                        &.medium {
                            color: $color-warning;
            
                            .icon {
                                color: $color-warning;
                            }
                        }
                    }
                }
            }
        }
    }
}
