@import 'utils/scss_variables.module.scss';

$icon-size: 20px;

.rule-action-display-wrapper {
    display: flex;
    align-items: center;
    color: $color-text-white;
    font-weight: bold;

    .icon {
        margin-right: 5px;
        width: $icon-size;
        height: $icon-size;
        min-width: $icon-size;
        min-height: $icon-size;
        
        &.allow {
            color: $color-success;
        }
        &.detect {
            color: $color-warning;
        }
        &.block {
            color: $color-error;
        }
        &.encrypt {
            color: $color-main;
        }
    }
}