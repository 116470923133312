@import 'utils/scss_variables.module.scss';

$icon-size: 20px;

.protected-pod-display-wrapper {
  display: flex;
  font-weight: bold;
  align-items: center;

  .icon {
    width: $icon-size;
    height: $icon-size;
    min-width: $icon-size;
    min-height: $icon-size;

    &.success {
      color: $color-success;
    }
    &.error {
      color: $color-error;
    }
  }
}