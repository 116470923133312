@import 'utils/scss_variables.module.scss';

.modal-outer .modal-container.agent-gather-information-modal {
    max-height: 600px;
    width: 600px;
}

.logs-download-container {
    margin-right: 10px;

    .generate-button {
        font-size:9px;
        height: 24px;
    }
    &.download {
        display: inline-block;

        .download-items {
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            
            a {
                color: $color-grey-black;
                text-transform: uppercase;
                margin-top: 5px;
                font-size: 10px;
            }
        }
    }
    &.error {
        color: $color-error;
        font-weight: bold;
    }
    &.generating {
        text-transform: uppercase;
    }
}