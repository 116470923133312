@import 'utils/scss_variables.module.scss';

.details-tabbed-page-wrapper {
    height: calc(100vh - #{$top-nav-height} - 20px);

    .deatils-tabbed-page-tabs-wrapper {
        position: relative;

        .deatils-tabbed-page-custom-header-wrapper {
            position: absolute;
            right: 10px;
            top: 10px;
        }
    }
}