@import 'utils/scss_variables.module.scss';

$dot-size: 4px;

.workload-unordered-list {
    .workload-unordered-list-item {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .list-item-dot {
            height: $dot-size;
            width: $dot-size;
            min-width: $dot-size;
            background-color: $color-main;
            border-radius: 50%;

            &.with-alert {
                background-color: $color-error;
            }
        }
        .list-item-content {
            font-size: 12px;
            line-height: 16px;
            margin-left: 10px;
        }
    }
}