@import 'utils/scss_variables.module.scss';

$blocked-icon-size: 14px;

.risks-scan-page {
    .breadcrumbs-title-header {
        margin-bottom: 10px;
        width: calc(100% - 200px);
    }
    .table-filters-form {
        margin-bottom: 15px;
    }
    .table-wrapper-new {
        .table-action-buttons-container {
            width: 270px;
            position: absolute;
            top: -44px;
            right: 0;
        }
    }
    .scan-progress-container {
        display: flex;
        align-items: center;
        font-size: 11px;

        &.empty {
            .progress-loader {
                background-color: transparent;
                border: 1px solid $color-grey;

                .line-loader-filler {
                    width: 0 !important;
                }
            }
        }
        &.done {
            .progress-loader .line-loader-filler {
                background-color: $color-success;
            }
            .icon {
                color: $color-success;
            }

            &.disabled {
                .progress-loader,
                .icon {
                    opacity: 0.4;
                }
            }
        }
        .progress-loader {
            width: 110px;
            margin-right: 10px;

            .line-loader-filler {
                background-color: $color-main;
            }
        }
        .icon {
            color: $color-grey;
            width: $blocked-icon-size;
            height: $blocked-icon-size;
            min-width: $blocked-icon-size;
            min-height: $blocked-icon-size;
        }
    }
    .vulnerabilities-cell-container {
        .vulnerabilities-wrapper {
            width: 385px;
            display: flex;
            justify-content: space-between;
        }
    }
    .cluster-risks-scan-images {
        button {
            text-align: left;
            line-height: 16px;
        }
    }
}