@import 'utils/scss_variables.module.scss';

.dot-loader-wrapper {
    display: flex;

    .loader-dot {
        margin: 1px;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        transform: scale(0.5);
        animation: scale-dot 1.2s ease-out infinite;
        &:nth-of-type(2) {
            animation-delay: 0.2s;
        }
        &:nth-of-type(3) {
            animation-delay: 0.3s;
        }
    }
}
  
@keyframes scale-dot {
    0% {
        transform: scale(0.5);
    }
    25% {
        transform: scale(1);
    }
    75% {
        transform: scale(0.5);
    }
    100% {
        transform: scale(0.5);
    }
}