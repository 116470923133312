@import 'utils/scss_variables.module.scss';

.runtime-connection-statistics {
    padding: 40px 20px;

    &.empty {
        text-align: center;
    }
    .graphs-wrapper {
        display: flex;
        justify-content: space-around;
        overflow: hidden;
        margin: 20px 0;

        .data-graph-container {
            width: 100%;
        }
    }
}