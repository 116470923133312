@import 'utils/scss_variables.module.scss';

.toggle-container {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;

    &.disabled {
        cursor: not-allowed;
    }
    &.full-width {
        width: 100%;
    }
    &.small {
        color: $color-grey-black;
        font-size: 9px;
        font-weight: bold;
    }
    &.dark {
        .react-toggle {
            .react-toggle-track,
            &:hover:not(.react-toggle--checked) .react-toggle-track {
                background-color: $color-neutral-dark;
            }
        }
    }
    .toggle-title {
        margin-right: 10px;
        color: $color-text-light;

        &.with-bold-text {
            font-weight: bold;
        }
        
        &.with-left-margin {
            margin-left: 10px;
            margin-right: 0;
        }
    }
    .react-toggle {
        border-radius: 50px;
        vertical-align: text-bottom;

        &:hover {
            .react-toggle-track {
                background-color: $color-main;
            }
        }
        &.react-toggle--checked {
            .react-toggle-track {
                background-color: $color-main-light;
            }
        }
        .react-toggle-thumb {
            box-shadow: none !important;
            outline: none;
            border-color: transparent;
            width: 14px;
            height: 14px;
        }
        .react-toggle-track {
            background-color: $color-main;
            width: 36px;
            height: 16px;
        }
        &.react-toggle--checked .react-toggle-thumb {
            left: 21px;
        }
    }
}