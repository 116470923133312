@import 'utils/scss_variables.module.scss';

.input-with-copy-wrapper {
    display: flex;
    align-items: center;

    textarea {
        width: 100%;
        font-size: 11px;
        background-color: inherit;
        color: $color-text-white;
        border-color: $color-neutral-grey-light;
        resize: none;
        height: 40px;
        padding: 10px;
        margin-right: 5px;

        &:focus {
            box-shadow: none;
            background-color: inherit;
            color: $color-text-white;
            border-color: $color-neutral-grey-light;
        }
    }
    .icon-copy {
        color: $color-neutral-grey;
    }
}