@import 'utils/scss_variables.module.scss';

.audit-page {
    padding: $main-content-padding;

    .table-filters-container {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }
    .table-wrapper-new {
        .table-action-buttons-container {
            width: 270px;
            position: absolute;
            top: -44px;
            right: 0;
        }
        .table .table-body {
            height: calc(100vh - #{$top-nav-height} - 185px);
        }
    }
}