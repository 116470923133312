@import 'utils/scss_variables.module.scss';

$identification-icon-size: 18px;
$sidecar-container-width: 30px;

.workload-details-tab-wrapper {
    .left-pane-wrapper {
        position: relative;

        .risk-reasons-wrapper {
            .risk-reasons-actions-wrapper {
                position: absolute;
                top: 40px;
                right: 30px;

                button:not(:last-child) {
                    margin-right: 10px;
                }
            }
            .risk-reason-wrapper {
                margin-bottom: 10px;

                .ps-checkbox {
                    margin-bottom: 0;
                }
                .risk-reason {
                    display: flex;
                    align-items: center;
            
                    &.ignored {
                        .icon {
                            opacity: 0.4;
                        }
                        .reason-text {
                            color: $color-grey;
                        }
                    }
                    .reason-text {
                        margin-left: 5px;
                        font-size: 12px;
                        line-height: 15px;
            
                        .risky-role {
                            cursor: pointer;
                            text-decoration: underline;
                            color: $color-main;
                        }
                    }
                }
            }
            .edit-page-link-notification {
                display: flex;
                align-items: center;
                margin-top: 40px;
                font-size: 12px;
                line-height: 9px;

                .round-icon-container {
                    margin-right: 7px;
                }
            }
            .empty-risk-reasons {
                font-size: 12px;
                line-height: 9px;
                margin-bottom: 10px;
            }
        }
    }
    .identification-content-wrapper {
        .identification-status-icon {
            margin-right: 5px;
            width: $identification-icon-size;
            height: $identification-icon-size;
            min-width: $identification-icon-size;
            min-height: $identification-icon-size;
            color: $color-warning-dark;
    
            &.success {
                color: $color-success;
            }
        }
        .identification-content-item {
            margin-top: 20px;
    
            .identification-content-item-wrapper {
                font-size: 12px;
                line-height: 16px;
                display: flex;
                align-items: center;
                margin-top: 10px;
            }
        }
        .sidecar-items-display-wrapper {
            margin-top: 20px;
    
            .sidecar-item-container {
                font-size: 12px;
                line-height: 16px;
                display: flex;
                align-items: center;
                margin-top: 10px;
                overflow-wrap: anywhere;
            }
        }
    }
    .key-value-list-wrapper {
        margin-bottom: 30px;
    }
}