.cloud-account-form-wrapper {
	.time-config-wrapper {
		display: flex;
		align-items: flex-end;

		.interval-type-input-field {
			width: 100px;
		}
		.ps-field-wrapper {
			margin-right: 20px;
		}
	}
}
