@import 'utils/scss_variables.module.scss';

.ps-field-wrapper.ps-text-area-wrapper {
    textarea {
        width: 100%;
        color: $color-text-white;
        outline: none;
        border: 1px solid $color-neutral-grey-light;
        background-color: inherit;
        border-radius: 2px;
        padding: 10px;
        resize: none;
        height: 150px;
        font-size: 12px;
    }
}