@import 'utils/scss_variables.module.scss';

.manage-users-page {
    &.global-admin-page {
        padding: $main-content-padding
    }
    .table-header-container {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .add-new-button {
            margin-right: 10px;
        }
    }
    .table-wrapper-new {
        .table-action-buttons-container {
            width: 270px;
            position: absolute;
            top: -44px;
            right: 0;
        }
        .table-container .table .actions-column-container .ps-dropdown.table-cell-action-container .dropdown-menu {
            width: 105px;
            transform: translate3d(-80px, 22px, 0px) !important;
        }
        .user-row-actions {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 120px;
        }
    }
}
.user-token-modal {
    .input-field-container {
        display: flex;
        align-items: center;

        .icon {
            margin-left: 5px;
            cursor: pointer;
            color: $color-main;
        }
    }
}
.delete-user-modal {
    .cd-policies-message {
        margin-bottom: 20px;
    }
}