@import 'utils/scss_variables.module.scss';

.modal-confirmation {
    .confirmation-actions-container {
        position: absolute;
        bottom: 20px;
        right: 20px;

        .confirmation-cancel-button {
            margin-right: 10px;
        }
        .confirmation-confirmation-button.alert {
            background-color: $color-error;
        }
    }
}