@import 'utils/scss_variables.module.scss';

.image-packages-modal {
    width: 640px !important;
    max-height: none !important;
    height: auto !important;

    .packages-table {
        width: 100%;
        table-layout: fixed;

        .table-body{
            max-height: 300px;
            padding-bottom: 20px;
        }
        .table-cell {
            width: 33.333%;
            font-size: 11px;
            border: 1px solid $color-neutral-grey-light;
            color: $color-text-white;
            padding: 15px;
            display: flex;
            align-items: center;
        }
        .table-row {
            display: flex;
        }
        .table-header .table-cell {
            text-transform: uppercase;
            font-size: 9px;
            font-weight: 700;
            color: $color-text-white;
            border: 0;
            border-bottom: 1px solid $color-neutral-grey-light;
            padding: 7px 15px;
        }
    }
}