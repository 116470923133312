@import 'utils/scss_variables.module.scss';

.code-zips-page {
    .table-wrapper-new {
        .table-action-buttons-container {
            position: absolute;
            top: -45px;
            right: 0;
        }
        .status-icon {
            color: $color-warning;

            &.approved {
                color: $color-success;
            }
        }
    }
}