@import 'utils/scss_variables.module.scss';

.test-status-wrapper {
    display: flex;
    align-items: center;
    font-weight: bold;

    .status-indecator {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        margin-right: 5px;
    }
}