@import 'utils/scss_variables.module.scss';

$validation-icon-size: 12px;

.trusted-signer-details-page-wrapper {
    .signer-details-header {
        display: flex;
        justify-content: space-between;
    }
    .signer-details-content {
        padding: 20px 0;

        .trusted-signer-key-display textarea {
            height: 144px;
        }
        .section-title {
            overflow: hidden;
        }
        .signer-details-title {
            font-size: 14px;
            font-weight: 700;
            line-height: 14px;
        }
        .scope-items-wrapper {
            &:not(:last-child) {
                margin-bottom: 50px;
            }
            .scope-items-title {
                margin-bottom: 10px;
            }
            .no-item-validation-name-wrapper {
                color: $color-grey;
                display: flex;
                align-items: center;
    
                .icon {
                    color: $color-warning;
                    margin-left: 5px;
                    height: $validation-icon-size;
                    min-height: $validation-icon-size;
                }
            }
        }
        
        
    }
}