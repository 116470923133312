@import 'utils/scss_variables.module.scss';

.ps-field-wrapper.ps-time-field-wrapper {
    .time-input {
        width: 100px !important;
        height: 38px;
        text-align: center;
        border: 1px solid $color-neutral-grey-light;
        background-color: transparent;
        border-radius: 4px;

        &:focus-visible {
            outline: none;
        }
    }
}