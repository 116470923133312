@import 'utils/scss_variables.module.scss';

.vulnerabilities-inner-container {
    display: flex;
    align-items: stretch;

    .packages-container {
        max-width: 520px;
        width: 100%;
        margin-right: 40px;
        padding: 24px 28px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    
        .package-details {
            width: 50%;
            padding-right: 10px;
        }
        .package-item {
            display: flex;
            margin-bottom: 24px;

            .item-label {
                color: $color-text-white;
                font-weight: 700;
                margin-right: 8px;
                min-width: 60px;
            }
            .item-value {
                color: $color-text-light;
            }
        }
        .more-container {
            flex-basis: 100%;
            text-align: right;
        }
    }

    .cvss-container {
        flex-grow: 1;

        .section-items {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .section-item {
                border-left: 1px solid $color-neutral-grey-light;
                width: 150px;
                padding-left: 8px;
                margin: 12px 16px 12px 0;

                .item-label,
                .item-value {
                    font-size: 9px;
                }
                .item-label {
                    text-transform: uppercase;
                    font-weight: 700;
                    color: $color-text-white;
                }
                .item-value {
                    margin-top: 4px;
                    color: $color-text-light;
                }
            }
        }
        .section-label {
            color: $color-text-white;
            font-weight: 700;

            &:not(:first-of-type) {
                margin-top: 15px;
            }
        }
        .no-cvss-data {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
        }
    }
}