$loader-size: 40px;
$loader-size-small: 20px;

.ps-loader {
    top: calc(50% - (#{$loader-size} / 2));
    left: calc(50% - (#{$loader-size} / 2));
    width: $loader-size !important;
    height: $loader-size !important;

    &.small {
        top: calc(50% - (#{$loader-size-small} / 2));
        left: calc(50% - (#{$loader-size-small} / 2));
        width: $loader-size-small !important;
        height: $loader-size-small !important;
    }
    &.absolute {
        position: absolute !important;
    }
    &.align-left {
        left: 0;
    }
}