@import 'utils/scss_variables.module.scss';

.function-vulnerabilities-tab-wrapper {
    .table-wrapper-new {
        .table-action-buttons-container {
            width: 100%;
        }
        .table .table-body {
            height: calc(100vh - #{$top-nav-height} - 330px);
        }
    }
}
