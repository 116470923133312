@import 'utils/scss_variables.module.scss';

.icon-container {
    display: inline-flex;
    align-items: center;
    padding: 5px;
    white-space: nowrap;

    .icon-title {
        float: left;
        margin-left: 5px;
        font-weight: bold;
        font-size: 10px;
        line-height: 22px;

        &.reverse {
            margin-right: 5px;
            margin-left: 0px;
        }
    }

    .arrow-icon {
        margin: 0 5px 4px 10px;
    }
    &:not(.disabled) {
        .icon {
            color: $color-main-light;
        }
        .icon-title {
            color: $color-text-white;
        }
    }
    &.disabled {
        cursor: not-allowed;

        .icon,
        .icon-title {
            color: $color-disabled;
        }
    }
    &.clickable {
        cursor: pointer;
    }
}