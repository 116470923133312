@import 'utils/scss_variables.module.scss';

.kubernetes-cluster-form-container {
    .cluster-description-wrapper {
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 30px;
    }
    .cluster-name-type-wrapper {
        display: flex;
        justify-content: space-between;

        > .ps-field-wrapper {
            width: 48%;
        }
    }
    .cluster-features-wrapper {
        .ps-checkbox-field-wrapper {
            margin-bottom: 0;

            .ps-checkbox {
                padding-left: 0;
            }
        }
    }
    .external-ca-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .ca-connector {
            font-size: 11px;
        }
        .external-ca-select {
            width: calc(100% - 120px);
        }
    }
    .message-container {
        display: flex;
        align-items: center;
        background-color: $color-grey-off-white;
        border-radius: 50px;
        max-width: 400px;
        padding-top: 20px;

        .icon {
            color: $color-main;
            margin-right: 5px;
        }
        .message {
            font-size: 10px;
            color: $color-grey-black;
            font-weight: 600;
        }
    }
    .sidecars-resources-wrapper {
        .inner-title {
            font-weight: bold;
            font-size: 10px;
            text-transform: uppercase;
            color: $color-text-light;
            margin-bottom: 10px;
        }
        .inner-double-field {
            display: flex;
            justify-content: space-between;

            .ps-field-wrapper {
                width: 49%;
            }
        }
    }
    .inner-ca-form-wrapper {
        padding-bottom: 20px;

        .ca-issuer-fields {
            display: flex;
            justify-content: space-between;

            .ps-field-wrapper {
                width: 49%;
            }
        }
        .ca-action-buttons {
            display: flex;
            justify-content: flex-end;

            .ca-submit-btn {
                margin-left: 5px;
            }
        }
    }
    .api-security-wrapper {
        margin-bottom: 50px;

        .ps-checkbox-field-wrapper {
            margin-bottom: 0;

            .ps-checkbox {
                padding-left: 0;
            }
        }
    }
    .scan-config-wrapper {
        .ps-checkbox-field-wrapper:not(:last-child) {
            margin-bottom: 0 !important;
        }
    }
}