@import 'utils/scss_variables.module.scss';

$apps-table-height: calc(100vh - 2 * #{$main-content-padding} - #{$top-nav-height} - 45px);

.workloads-page {
    .page-content {
        .table-container {
            .ReactTable {
                max-height: $apps-table-height;
                min-height: $apps-table-height;
            }
            .columns-toolbar {
                height: $apps-table-height;
            }
        }
    }
    
}      