@import 'utils/scss_variables.module.scss';

.ps-tooltip {
    max-width: 600px;
    font-size: 11px !important;
    font-weight: normal !important;
    text-transform: none !important;
    z-index: $top-layer-z-index !important;

    &:not(.center) {
        text-align: left !important;
    }
}