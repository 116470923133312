@import 'utils/scss_variables.module.scss';

$inner-padding: 10px;

.ps-clusters-namespaces-filter-field-wrapper {
    .selector-wrapper {
        .input-field {
            border-radius: 2px;
            border: solid 1px $color-neutral-grey-light;
            color: $color-text-white;
            background-color: inherit;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding: 0 3px;
            padding-right: 15px;
            position: relative;

            .placeholder {
                color: $color-text-light;
            }
            .open-menu-icon {
                position: absolute;
                top: -1px;
                right: 0;
                color: $color-text-light;
            }
        }
    }
    .dropdown-menu {
        padding: 0;
        border-radius: 2px;
        background-color: $color-background;
        border: solid 1px $color-neutral-grey-light;
        color: $color-text-light;

        &:focus {
            outline: none;
        }
        .namespaces-multiselect-content-wrapper {
            .namespace-back-button-wrapper {
                display: flex;
                align-items: center;
                font-weight: bold;
                font-size: 10px;
                padding-top: $inner-padding;
                cursor: pointer;
            }
            .multiselect-content-container {
                margin-top: calc(-#{$inner-padding});
            }
        }
        .multiselect-content-container {
            padding: $inner-padding;
            
            .ps-checkbox-wrapper .ps-checkbox {
                padding: 0;
            }
            .multiselect-items-container {
                margin-left: 10px;
                max-height: 200px;
                overflow-y: auto;
            }
        }
    }
    
}