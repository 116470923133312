@import 'utils/scss_variables.module.scss';

.runtime-workloads-page {
    .table-filters-container {
        height: 80px;

        .table-filters-line {
            display: flex;
            justify-content: space-between;

            .table-filters-form {
                margin: 15px 0;
            }
            .runtime-system-pods-toggle-wrapper {
                margin-top: 20px;
            }
        }
    }
    .table-wrapper-new {
        .table .table-body {
            height: calc(100vh - 2 * #{$main-content-padding} - #{$top-nav-height} - 200px);
        }
        .table-action-buttons-container {
            position: absolute;
            top: -95px;
            right: 0;
        }
    }
    .security-threats-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .icon {
            margin: 0 10px;

            &.disable {
                color: $color-disabled !important;
                cursor: auto !important;;
            }
        }
        .higest-vulnerability-icon-wrapper.disabled {
            cursor: auto !important;;

            .vulnerability-icon {
                color: $color-grey !important;
                
            }
        }
    }
    .workload-risk-container {
        font-weight: 700;
    }
}