@import 'utils/scss_variables.module.scss';

.ci-cd-policies-container {
    height: calc(100vh - 2 * #{$main-content-padding} - #{$top-nav-height} - 10px);
    margin: 0 calc(-1 * #{$main-content-padding});

    .scan-policy-container {
        padding: 0 $main-content-padding;
        height: 50%;
        position: relative;

        &:first-child {
            border-bottom: 1px solid $color-neutral-grey-light;
        }
        &:last-child {
            margin-top: 10px;
        }
        .new-policy-scan-button {
            width: 100px;
        }
        .policy-scans-wrapper {
            margin-top: 10px;
            overflow: auto;
            height: calc(100% - 45px);
        }
        .empty-scan-policy-icon-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;

            .empty-data-title {
                font-size: 18px;
                margin-bottom: 10px;
                color: $color-text-light;
            }
            .icon {
                width: 310px;
                height: 164px;
            }
        }
    }
    .scan-policy-loader-wrapper {
        height: 50%;

        &:first-child {
            border-bottom: 1px solid $color-main-dark;
        }
    }
}