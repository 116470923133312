@import 'utils/scss_variables.module.scss';

$small-icon-size: 12px;
$policy-icon-container-size: 30px;
$policy-icon-size: 18px;
$title-width: 250px;

.policy-item-container {
    display: flex;
    align-items: center;
    background-color: rgba($color-status-blue, 0.1);
    box-shadow: 0px 5px 10px rgba(34, 43, 54, 0.11);
    outline: none;
    min-height: 77px;
    position: relative;
    cursor: pointer;

    &.disabled {
        .implicit-rule-tag {
            opacity: 50%;
        }
    }
    .item-title-wrapper {
        width: $title-width;
        display: flex;
        align-items: center;
        border-right: 1px solid $color-neutral-grey-light;

        .policy-item-title {
            font-weight: bold;
            font-size: 11px;
            overflow: hidden;
            text-overflow: ellipsis;
            overflow-wrap: break-word;
            margin-right: 5px;
            color: $color-text-white;
        }
        .policy-icon-container {
            margin: 10px;

            &:not(.disabled) {
                background-color: $color-warning;

                &.allowed {
                    background-color: $color-success;
                }
                &.blocked {
                    background-color: $color-error;
                }
                &.encrypted,
                &.injection,
                &.ignored {
                    background-color: $color-main-light;
                }
            }
        }
        .implicit-rule-tag {
            background-color: $color-main-medium;
            font-size: 9px;
            font-weight: bold;
            display: flex;
            align-items: center;
            height: 18px;
            padding-right: 7px;
            padding-left: 5px;
            color: $color-white;
            position: absolute;
            top: 0;
            left: 0;
            text-transform: uppercase;

            .icon {
                color: $color-white;
                margin-right: 3px;
                width: $small-icon-size;
                height: $small-icon-size;
                min-width: $small-icon-size;
                min-height: $small-icon-size;
            }
        }
    }
    .content-container {
        padding: 25px 15px;
        max-width: calc(100% - #{$title-width});
        font-size: 11px;
        color: $color-text-white;
        display: flex;
        align-items: center;
        white-space: break-spaces;

        .origin-icon {
            margin-right: 4px;
            color: $color-text-white;
        }
        &.disabled {
            .relationship {
                color: $color-disabled !important;
            }
            .origin-icon {
                color: $color-disabled;
            }
        }
        .relationship {
            color: $color-warning;
            font-weight: bold;
            line-height: 11px;

            &.allowed {
                color: $color-success;
            }
            &.blocked {
                color: $color-error;
            }
            &.encrypted,
            &.injection,
            &.ignored {
                color: $color-main-light;
            }
        }
    }
    .policy-item-actions {
        position: absolute;
        top: 5px;
        right: 5px;
        display: none;
        color: $color-neutral-grey;

        .actions-menu-container {
            cursor: pointer;
            
            .dropdown-menu {
                transform: translate3d(-120px, 22px, 0px) !important;
                padding: 10px;
                background-color: $color-background;
                border: 1px solid $color-neutral-grey-light;

                > div:hover {
                    background-color: $color-hover;
                }
            }
        }
    }
    &:hover {
        box-shadow: 0 0 14px 0 rgba(34, 43, 54, 0.28);
    }
    &:hover,
    &:active {
        .policy-item-actions {
            display: flex;
        }
    }
    &.selected {
        border: 2px solid $color-neutral-grey-lighter;
    }
}