@import 'utils/scss_variables.module.scss';

$async-select-indicator-width: 32px;
$async-select-indicator-icon-size: 13px;

.ps-field-wrapper.ps-async-select-field-wrapper {
    .selector-wrapper {
        position: relative;

        .ps-async-select {
            &.small {
                .async-select__indicators {
                    max-width: $async-select-indicator-width;

                    .async-select__indicator-separator {
                        display: none;
                    }
                    .async-select__dropdown-indicator {
                        padding: 0;
                        right: 4px;

                        svg {
                            height: $async-select-indicator-icon-size;
                            width: $async-select-indicator-icon-size;
                        }
                    }
                    .async-select__clear-indicator {
                        padding: 0;

                        svg {
                            height: $async-select-indicator-icon-size;
                            width: $async-select-indicator-icon-size;
                        }
                    }
                }
            }
            &:not(.small) .async-select__control {
                .async-select__value-container.async-select__value-container--has-value {
                    &:active,
                    &:hover,
                    &:focus-within {
                        > :not(.async-select__multi-value) {
                            display: block;
                        }
                    }
                    > :not(.async-select__multi-value) {
                        display: none;
                    }
                }
            }
            .async-select__indicators .async-select__indicator svg {
                color: $color-neutral-grey;
            }
            .async-select__control {
                border-color: $color-neutral-grey-light;
                background-color: inherit;
                border-radius: 2px;

                .async-select__value-container {
                    .async-select__single-value,
                    .async-select__input-container {
                        color: $color-text-white;
                    }
                }
                .async-select__placeholder {
                    color: $color-text-light;
                }
                &.async-select__control--is-disabled {
                    .async-select__placeholder,
                    .async-select__indicators {
                        display: none;
                    }
                    .async-select__multi-value {
                        background-color: $color-disabled;
                    }
                }
                &.async-select__control--is-focused {
                    box-shadow: none;
                }
                
                .async-select__multi-value,
                .async-select__multi-value:focus,
                .async-select__multi-value__label:focus,
                .async-select__multi-value__remove:focus {
                    outline: none;
                }
                .async-select__multi-value {
                    background-color: $color-main;
                    color: $color-white;
                    border-radius: 50px;
        
                    .async-select__multi-value__label {
                        color: $color-white;
                        padding-left: 10px;
                    }
                    .async-select__multi-value__remove {
                        border-radius: 50px;
                        cursor: pointer;
                        opacity: 0.4;
        
                        &:hover {
                            background-color: $color-main;
                            color: $color-white;
                            opacity: 1;
                        }
                    }
                }
            }
            .async-select__menu {
                background-color: $color-background;
                border: 1px solid $color-neutral-grey-light;

                .async-select__option {
                    background-color: $color-background;
                    
                    &:hover:not(.disabled) {
                        background-color: $color-hover;
                    }
                }
            }
        }
    }
}