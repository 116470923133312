$icon-size: 14px;

.api-token-form-wrapper {
    .token-attribute-fields-wrapper {
        display: flex;
        justify-content: space-between;

        > .ps-field-wrapper {
            width: 49%;
        }
    }
    .token-injection-button-wrapper {
        margin-top: 20px;
        float: left;
        margin-right: 10px;
    }
    .token-apis-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .api-connector {
            font-size: 11px;
        }
        .token-apis-select {
            width: calc(100% - 120px);
        }
    }
    .form-inner-field-wrapper {
        margin-top: -20px;
    }
    .inner-api-form-wrapper {
        padding-bottom: 20px;

        .api-action-buttons {
            display: flex;
            justify-content: flex-end;

            .api-submit-btn {
                margin-left: 5px;
            }
        }
    }
}