@import 'utils/scss_variables.module.scss';

$sort-icon-size: 8px;
$table-default-height: calc(100vh - 2 * #{$main-content-padding} - #{$top-nav-height} - 145px);
$hover-background-color: $color-hover;

.table-wrapper-new {
    position: relative;

    .table-action-buttons-container {
        display: inline-flex;
        padding: 5px 0;
        justify-content: flex-end;

        > :not(:last-child) {
            margin-right: 10px;
        }
        .selected-rows-actions-container {
            display: flex;
        }
    }
    .table-no-header-loader-container {
        min-height: $table-default-height;
    }
    .table-empty-results {
        font-size: 11px;
        padding: 20px;
        text-align: center;
        min-height: $table-default-height;
    }
    .table-container {
        position: relative;
        
        .table {
            width: 100%;
            margin: auto;
            margin-top: 10px;
            border-collapse: separate;
            border-spacing: 0;
            overflow: auto;
            color: $color-text-white;

            .table-head {
                background-color: $color-main;
                position: sticky;
                top: 0;
                z-index: 1;

                .table-th {
                    background-color: $color-main;
                    color: $color-text-white;
                    border: none;
                }
            }
            .table-body {
                height: $table-default-height;

                .table-tr-wrapper:not(:hover) {
                    .table-tr.with-row-actions:first-child {
                        background-color: $hover-background-color;

                        .table-td .actions-column-container {
                            visibility: visible;
                        }
                    }
                }
                .table-tr {
                    &.clickable:hover {
                        cursor: pointer;
                        background-color: $hover-background-color;
                    }
                    &.selected {
                        background-color: $hover-background-color;
                    }
                    &.with-row-actions {
                        &:hover {
                            background-color: $hover-background-color;
    
                            .table-td .actions-column-container {
                                visibility: visible;
                            }
                        }
                        .table-th,
                        .table-td {
                            &:nth-last-child(2) {
                                border-right: none;
                            }
                            .actions-column-container {
                                visibility: hidden;
    
                                .icon {
                                    color: $color-main-light;
    
                                    &.disabled {
                                        color: $color-disabled;
                                    }
                                    &:not(.disabled):hover {
                                        opacity: 1;
                                    }
                                }
                            }
                        }
                    }
                    .table-td:first-of-type{
                        border-left: 0px;
                    }
                    .table-td:last-of-type {
                        border-right: 0px;
                    }
                }
            }
            .table-th,
            .table-td {
                padding: 7px 10px;
                border-bottom: 1px solid $color-neutral-grey-light;
                font-size: 11px;
            }
            .table-th {
                color: $color-main;
                font-size: 9px;
                line-height: 12px;
                font-weight: bold;
                text-transform: uppercase;

                .sort-icon {
                    width: $sort-icon-size;
                    height: $sort-icon-size;
                    min-width: $sort-icon-size;
                    min-height: $sort-icon-size;
                    margin-left: 5px;

                    &:not(.sorted) {
                        display: none;
                    }
                    &.rotate {
                        transform: rotate(180deg);
                    }
                }
                &:hover {
                    .sort-icon {
                        display: inline-block;
                    }
                }
            }
            .table-td {
                display: flex;
                justify-content: center;
                flex-direction: column;
                padding: 15px;

                &:not(.is-static) {
                    overflow: hidden;
                }
                &.center {
                    align-items: center;
                }
            }
            .resizer {
                right: 0;
                width: 10px;
                height: 100%;
                position: absolute;
                top: 0;
                z-index: 1;
                touch-action :none;
            }
            .row-select-checkbox {
                margin: 0;
                padding: 0;
            }
            .row-expand-icon {
                color: $color-main-light;
            }
            .actions-column-container {
                display: flex;
                align-items: center;
                justify-content: space-between;
                
                .ps-dropdown.table-cell-action-container {
                    .dropdown-menu {
                        min-width: 80px;
                        padding: 10px;
                        font-size: 11px;
                        transform: translate3d(-55px, 22px, 0px) !important;

                        &::before {
                            content: '';
                            position: absolute;
                            top: -7px;
                            right: 6px;
                            display: inline-block;
                            border-right: 7px solid transparent;
                            border-bottom: 7px solid #CCC;
                            border-left: 7px solid transparent;
                            border-top-color: rgba(0, 0, 0, 0.2);
                        }
                        &::after {
                            content: '';
                            position: absolute;
                            top: -6px;
                            right: 7px;
                            display: inline-block;
                            border-right: 6px solid transparent;
                            border-bottom: 6px solid white;
                            border-left: 6px solid transparent;
                        }
                        &:focus {
                            outline: none;
                        }
                        .table-item-action {
                            cursor: pointer;

                            &:focus,
                            &:hover {
                                outline: none;
                                font-weight: bold;
                            }
                            &.disabled {
                                color: $color-disabled;
                                font-weight: normal;
                                cursor: not-allowed;
                            }
                        }
                    }
                }
            }
        }
    }
    .status-indicator-wrapper {
        display: flex;
        align-items: center;

        .status-indicator {
            min-width: 6px;
            height: 6px;
            border-radius: 50px;
            background-color: $color-error;
            margin-right: 7px;
            
            &.active {
                background-color: $color-success;
            }
            &.warning {
                background-color: $color-warning;
            }
        }
    }
    .with-subtitile-wrapper {
        margin: auto 0;

        .cell-sub-title {
            font-size: 9px;
            color: $color-grey; 
        }
    }
    .table-utils-status-icon-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;

        .status-icon {
            color: $color-grey;

            &.success {
                color: $color-success;
            }
            &.error {
                color: $color-error;
            }
        }
    }
    .table-utils-expendable-tags-cell {
        display: flex;
        flex-direction: column;
        
        .arrow-icon {
            align-self: center;
            color: $color-neutral-grey;
        }
    }
}