@import 'utils/scss_variables.module.scss';

$tab-height: 40px;
$tab-height-small: 30px;

.tabs-container {
    height: $tab-height;
    box-sizing: border-box;
    border-bottom: 1px solid $color-neutral-grey-light;
    background-color: $color-background;
    box-shadow: -1px 1px 4px rgba(55, 70, 95, 0.11965);
    padding: 0 $main-content-padding;
    display: flex;

    .tabs-nav-item {
        text-decoration: none;
        color: $color-text-white;
        padding: 14px 20px;
        height: $tab-height;
        box-sizing: border-box;
        font-weight: bold;
        font-size: 10px;
        text-transform: uppercase;

        &.active {
            border-bottom: 2px solid $color-main-light;
            color: $color-main-light;
            cursor: default;
        }
        &:hover:not(.active) {
            cursor: pointer;
        }
        &.disabled {
            pointer-events: none;
            color: $color-disabled;
        }
        .tabs-nav-item-display {
            display: flex;

            .tabs-nav-item-display-indicator {
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background-color: $color-main;
                margin-left: 2px;
            }
        }
    }
    &.embedded {
        background-color: $color-main-dark;
        box-shadow: none;
        padding: 0;
    }
    &.secondary {
        background-color: rgba($color-status-blue, 0.1);
        color: $color-text-white;
        border-bottom: none;

        .tabs-nav-item {
            font-weight: normal;

            &.active {
                border-bottom: none;
                color: $color-text-white;
                font-weight: bold;
            }
        }
    }
}