@import 'utils/scss_variables.module.scss';

$item-padding: 15px;

.risk-management-page-wrapper {
    padding: $main-content-padding;

    .risk-items-wrapper {
        .risk-item-wrapper {
            margin: 15px 0;
            padding: 15px;
            background-color: $color-main-dark;
            border: 1px solid $color-neutral-grey-light;
            display: flex;
            align-items: center;
            justify-content: space-between;
            min-height: calc(#{$default-icon-size} + 2 * #{$item-padding} + 2px);
            
            &:not(.disabled) {
                cursor: pointer;
            }
            &:hover {
                .risk-item-actions {
                    display: flex;
                }
            }
            .risk-item-description {
                font-size: 10px;
                line-height: 12px;
            }
            .risk-item-actions {
                display: none;

                .icon {
                    color: $color-neutral-grey;
                }
            }
        }
    }
}