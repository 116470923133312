@import 'utils/scss_variables.module.scss';

.tabbed-page-section-wrapper {
    .tabs-container:not(.secondary) {
        background-color: $color-background-medium;
        padding: 0;
    }
    .tab-content {
        height: 100%;
        min-height: 485px;

        .select-panes-wrapper {
            min-height: 485px;
        }
    }
}