@import 'utils/scss_variables.module.scss';

.functions-page {
    .table-filters-container {
        height: 70px;

        .table-filters-form {
            margin: 15px 0;
        }
    }
    .table-wrapper-new {
        .table .table-body {
            height: calc(100vh - 2 * #{$main-content-padding} - #{$top-nav-height} - 180px);
        }
        .table-action-buttons-container {
            position: absolute;
            top: -85px;
            right: 0;
        }
    }
    .security-threats-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .icon {
            margin: 0 7px;

            &.disabled {
                cursor: auto;
            }
        }
    }
}