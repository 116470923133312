.mini-dashboard-wrapper {
    display: flex;
    align-items: center;

    .total-container {
        min-width: 70px;
        width: 70px;
        font-size: 11px;
        margin-right: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .summary-items-container {
        display: flex;

        .mini-dashboard-item {
            display: flex;
            align-items: center;

            .item-count {
                width: 30px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}